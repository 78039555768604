import './App.css';
import AppHeader from "./components/app-header/app-header";
import AppBody from "./components/app-body/app-body";
import AppFooter from "./components/app-footer/app-footer";
import { useTranslation  } from "react-i18next";
import ConnectWallet from './ConnectWallet';
import ConnectedWallet from './ConnectedWallet';
import SelectTokenModal from './SelectTokenModal';
import NetworkData from './token_data.json'

import { IProvider } from '@walletconnect/universal-provider'
import { WalletConnectModal } from '@walletconnect/modal'
import { useEffect, useState } from 'react';
import axios from 'axios';
import { headers } from '.';
import LoadingModalComponent from './LoadingModal';

// TypeScript declarations


const App = ({universalProvider, walletConnectModal, web3Provider}: {universalProvider: any, walletConnectModal: WalletConnectModal, web3Provider: any}) => {
  // const networks: any =  NetworkData.networks
  const [t, i18n] = useTranslation()
  universalProvider.setDefaultChain('eip155:' + 1)
  const [networks, setNetworks] = useState<any[]|null>(null)
  const [tokens, setTokens] = useState<any[]|null>(null)
  const [currentNetwork, setCurrentNetwork] = useState<any>(null)
  const [fromTokenData, setFromTokenData] = useState<any|null>(null)
  const [toTokenData, setToTokenData] = useState<any|null>(null)
  // react hooks
  console.log('App Rerender')
  useEffect(() => {
    console.log("useEffect")
    async function getNetworks() {
      const response = await axios.post('https://app.sometestdomain.xyz/api/networks', {}, {headers: headers})
      const respData = await response.data
      setNetworks(respData)
      console.log(respData[0])
      setCurrentNetwork(respData[0])
      getTokens(respData[0].id)
    }
    async function getTokens(network: number) {
      const response = await axios.post('https://app.sometestdomain.xyz/api/network', {"chainId": network}, {headers: headers})
      const respData = await response.data
      setTokens(respData.tokens)
      setFromTokenData(respData.tokens[0])
      console.log(respData.tokens[0])
    }
    if (!networks) {
        getNetworks()
    }
  })
  return (
    <>
      <div>
          <AppHeader t={t} i18n={i18n} networks={networks} universalProvider={universalProvider} walletConnectModal={walletConnectModal} dt={currentNetwork} setDt={setCurrentNetwork} setFromTokenData={setFromTokenData} setToTokenData={setToTokenData} currentNetwork={currentNetwork} setCurrentNetwork={setCurrentNetwork} setTokens={setTokens} />
          <AppBody t={t} i18n={i18n} networks={networks} universalProvider={universalProvider} web3Provider={web3Provider} dt={currentNetwork} setDt={setCurrentNetwork}
          fromTokenData={fromTokenData} toTokenData={toTokenData} setFromTokenData={setFromTokenData} setToTokenData={setToTokenData} />
          <AppFooter t={t} i18n={i18n} />
      </div>
      <div id='connect-wallet'>
          <ConnectWallet t={t} i18n={i18n} />
      </div>
      <div id='connected-wallet'>
          <ConnectedWallet t={t} i18n={i18n} universalProvider={universalProvider} currentNetwork={currentNetwork} tokens={tokens} />
      </div>
      <div id='select-token-modal'>
          <SelectTokenModal t={t} i18n={i18n} networks={networks} universalProvider={universalProvider} currentNetwork={currentNetwork} setCurrentNetwork={setCurrentNetwork} setFromTokenData={setFromTokenData} setToTokenData={setToTokenData} setTokens={setTokens} tokens={tokens} fromTokenData={fromTokenData} toTokenData={toTokenData} />
      </div>
      <div id='loading-modal'>
        <LoadingModalComponent t={t} />
      </div>
    </>
  );
}

export default App;
