import './ConnectWallet.css'
import { ReactComponent as CloseConnectWalletIcon } from './close-connect-wallet-icon.svg'
import { ReactComponent as ConnectWalletSettingsIcon } from './connect-wallet-settings-icon.svg'
import { ReactComponent as TrustWalletIcon } from './trust-logo.svg'
import { ReactComponent as CoinbaseWalletIcon } from './coinbase-logo.svg'
import { ReactComponent as ExodusWalletIcon } from './exodus-logo.svg'
import { ReactComponent as FrontierWalletIcon } from './frontier-logo.svg'
import { ReactComponent as CloseSettingsIcon } from './close-settings-icon.svg'
import { ReactComponent as WalletConnectIcon } from './walletconnect-icon.svg'
import { ReactComponent as ThemeLightIcon } from './theme-light-icon.svg'
import { ReactComponent as ThemeDarkIcon } from './theme-dark-icon.svg'
import { ReactComponent as SettingsSelectIcon } from './settings-slect-arrow-icon.svg'
import UniswapWalletIcon from "./uniswap-wallet-icon.png";
import PhantomWalletIcon from "./phantom-logo.png";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import i18next from "i18next";

const ConnectWallet = ({t, i18n}: {t: any, i18n: any}) => {
    
    const openSettingMenu = (event: any) => {
        var e = event.target.closest('.settings-status')
        e?.classList.add('settings-open')
    }
    
    useEffect(() => {
        const a = localStorage.getItem("i18nextLng")
        if (a && a.length > 2) {
            i18next.changeLanguage("en");
        }
    }, []);

    const backWrapperModalClick = (event: any) => {
        document.querySelector('body')?.classList.remove('connect-open')
    }

    const closeConnectWallet = (event: any) => {
        document.querySelector('body')?.classList.remove('connect-open')
    }

    return (
        <div className='connection-drawer'>
            <div data-testid="close-connect-drawer" className='close-connect-wallet' onClick={closeConnectWallet}>
                <CloseConnectWalletIcon />
            </div>
            <div className='connection-drawer-background' onClick={backWrapperModalClick}>
            </div>
            <div className='connection-drawer-wrapper'>
                <div id='wallet-dropdown-scroll-wrapper' className='wallet-dropdown-scroll-wrapper'>
                    <div className='wallet-dropdown-default-menu settings-status'>
                        <div className='wallet-dropdown-default-menu-wrapper'>
                            <div className='wallet-dropdown-menu-header'>
                                <div className='wallet-dropdown-menu-header-title'>
                                    {t("Connect a wallet")}
                                </div>
                                <button data-testid="wallet-settings" onClick={openSettingMenu} className='wallet-dropdown-settings-button'>
                                    <span className='wallet-dropdown-settings-sapn'>
                                        <ConnectWalletSettingsIcon />
                                    </span>
                                </button>
                            </div>
                            <div className='wallet-dropdown-menu-body'>
                                <div className='wallet-dropdown-menu-body-wrapper'>
                                    <div className='wallet-options-grid' data-testid="option-grid">
                                        <OptionWrapper selected={true} name='Trust Wallet' Img={TrustWalletIcon} svg_or_png={false} recent={true} t={t} i18n={i18n} />
                                        <OptionWrapper selected={false} name='Uniswap Wallet' Img={UniswapWalletIcon} svg_or_png={true} recent={false} t={t} i18n={i18n} />
                                        <OptionWrapper selected={false} name='Phantom' Img={PhantomWalletIcon} svg_or_png={true} recent={false} t={t} i18n={i18n} />
                                        <OptionWrapper selected={false} name='Exodus' Img={ExodusWalletIcon} svg_or_png={false} recent={false} t={t} i18n={i18n} />
                                        <OptionWrapper selected={false} name='Frontier Wallet' Img={FrontierWalletIcon} svg_or_png={false} recent={false} t={t} i18n={i18n} />
                                        <OptionWrapper selected={false} name='WalletConnect' Img={WalletConnectIcon} svg_or_png={false} recent={false} t={t} i18n={i18n} />
                                        <OptionWrapper selected={false} name='Coinbase Wallet' Img={CoinbaseWalletIcon} svg_or_png={false} recent={false} t={t} i18n={i18n} />
                                    </div>
                                </div>
                                <div className='wallet-dropdown-menu-footer'>
                                    <div className='wallet-dropdown-menu-footer-wrapper'>
                                        <div className='wallet-dropdown-menu-footer-text'>
                                            {t("By connecting a wallet, you agree to Uniswap Labs")}'
                                            <a href="/" target='_blank'>{t("Terms of Service")}</a>
                                            {t("and consent to its")}
                                            <a href="/" target='_blank'>{t("Privacy Policy")}.</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ConnectWalletSettingsBlock t={t} i18n={i18n} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export const ConnectWalletSettingsBlock = ({t, i18n}: {t: any, i18n: any}) => {

    const closeSettingMenu = (event: any) => {
        var e = event.target.closest('.settings-status')
        console.log(e)
        e?.classList.remove('settings-open')
    }

    const toglerButtonClick = (event: any) => {
        var el = event.target;
        if (!el.classList.contains('wallet-settings-menu-section-toggle-button')) {el = el.closest('.wallet-settings-menu-section-toggle-button')}
        if (el.ariaSelected === 'true') {
            el.ariaSelected = 'false'
        }
        else {
            el.ariaSelected = 'true'
        }
    }
    const selectThemeClick = (event: any) => {
        var all_settings_togler = document.querySelectorAll('.wallet-settings-toggler')
        var el = event.target
        if (!el.classList.contains('wallet-settings-toggler')) {
            el = el.closest('.wallet-settings-toggler')
        }
        all_settings_togler.forEach((e) => {
            e.classList.remove('selected')
        })
        el.classList.add('selected')
    }

    return (
        <div className='wallet-dropdown-settings-menu-wrapper'>
            <div className='wallet-dropdown-settings-menu-header'>
                <CloseSettingsIcon onClick={closeSettingMenu} />
                <span className='wallet-dropdown-settings-menu-title'>
                    <div className='wallet-dropdown-settings-menu-title-div'>
                        {t("Settings")}
                    </div>
                </span>
            </div>
            <div className='wallet-dropdown-settings-menu-body'>
                <div>
                    <div className='wallet-dropdown-settings-menu-body-title'>
                        {t("Preferences")}
                    </div>
                    <div className='wallet-settings-menu-sections'>
                        <div className='wallet-settings-menu-section theme'>
                            <div className='wallet-settings-menu-section-name'>
                                <div className='wallet-settings-menu-section-name-wrapper'>
                                    {t("Theme")}
                                </div>
                            </div>
                            <div className='wallet-settings-togglers'>
                                <div className='wallet-settings-togglers-wrapper'>
                                    <div className='wallet-settings-toggler selected' onClick={selectThemeClick}>
                                        <div className='wallet-settings-toggler-wrapper'>
                                            {t("Auto")}
                                        </div>
                                    </div>
                                    <div className='wallet-settings-toggler' onClick={selectThemeClick}>
                                        <div className='wallet-settings-toggler-wrapper'>
                                            <ThemeLightIcon />
                                        </div>
                                    </div>
                                    <div className='wallet-settings-toggler' onClick={selectThemeClick}>
                                        <div className='wallet-settings-toggler-wrapper'>
                                            <ThemeDarkIcon />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='wallet-settings-menu-section'>
                            <div className='wallet-settings-menu-section-name'>
                                <div className='wallet-settings-menu-section-name-wrapper'>
                                    {t("Hide small balances")}
                                </div>
                            </div>
                            <button role='option' onClick={toglerButtonClick} aria-selected='false' className='wallet-settings-menu-section-toggle-button'>
                                <span className='wallet-settings-menu-section-toggle-span'></span>
                            </button>
                        </div>
                        <div className='wallet-settings-menu-section'>
                            <div className='wallet-settings-menu-section-name'>
                                <div className='wallet-settings-menu-section-name-wrapper'>
                                    {t("Hide unknown tokens & NFTs")}
                                </div>
                            </div>
                            <button role='option' onClick={toglerButtonClick} aria-selected='false' className='wallet-settings-menu-section-toggle-button'>
                                <span className='wallet-settings-menu-section-toggle-span'></span>
                            </button>
                        </div>
                        <div className='wallet-settings-menu-section'>
                            <div className='wallet-settings-menu-section-name mr-10'>
                                <div className='wallet-settings-menu-section-name-wrapper'>
                                    <div className='wallet-settings-menu-section-name-wrapper-text'>
                                        {t("Allow analytics")}
                                    </div>
                                </div>
                                <div className='wallet-settings-menu-section-name-wrapper'>
                                    <div className='wallet-settings-menu-section-name-wrapper-text-third'>
                                        {t("We use anonymized data to enhance your experience with Uniswap Labs products")}.
                                    </div>
                                </div>
                            </div>
                            <button role='option' onClick={toglerButtonClick} aria-selected='false' className='wallet-settings-menu-section-toggle-button'>
                                <span className='wallet-settings-menu-section-toggle-span'></span>
                            </button>
                        </div>
                        <div className='wallet-settings-menu-section'>
                            <div className='wallet-settings-menu-section-name'>
                                <div className='wallet-settings-menu-section-name-wrapper'>
                                    {t("Show testnets")}
                                </div>
                            </div>
                            <button role='option' onClick={toglerButtonClick} aria-selected='false' className='wallet-settings-menu-section-toggle-button'>
                                <span className='wallet-settings-menu-section-toggle-span'></span>
                            </button>
                        </div>
                    </div>
                    <div className='wallet-settings-select-section'>
                        <div className='language-settings-select'  data-test-id='language-settings-button'>
                            <div className='language-settings-select-wrapper'>
                                {t('Language')}
                            </div>
                            <div className='language-settings-select-button'>
                                <div className='language-settings-select-button-text'>
                                    English
                                </div>
                                <SettingsSelectIcon />
                            </div>
                        </div>
                    </div>
                    <div className='wallet-settings-select-section'>
                        <div className='language-settings-select'  data-test-id='currency-settings-button'>
                            <div className='language-settings-select-wrapper'>
                                {t("Currency")}
                            </div>
                            <div className='language-settings-select-button'>
                                <div className='language-settings-select-button-text'>
                                    USD
                                </div>
                                <SettingsSelectIcon />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='git-version-row'>
                    <div className='git-reference-element'>
                        <div className='git-version-text'>
                            {t("Version")} 40a935
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConnectWallet;

export const OptionWrapper = ({selected, name, Img, svg_or_png, recent, t, i18n}: {selected: boolean, name: string, Img: any, svg_or_png: boolean, recent: boolean, t: any, i18n: any}) => {

    return (
        <div className={`option-wrapper ${selected ? 'selected': ''}`}>
            <button data-testid="wallet-option-injected" className='wallet-option-injected'>
                <div className='wallet-option-card'>
                    <div className='wallet-option-card-icon-wrapper'>
                        {svg_or_png ? <img src={Img} alt="" className='wallet-option-card-icon' />: <Img />}
                    </div>
                    <div className='wallet-option-card-header-title'>
                        {name}
                    </div>
                </div>
                {recent ? <RecentSubTitle t={t} i18n={i18n} />: ''}
            </button>
        </div>
    )
}

export const RecentSubTitle = ({t, i18n}: {t: any, i18n: any}) => {

    return (
        <div className='wallet-selected-sub-title'>
            <div className='wallet-selected-sub-title-wrapper'>
                {t("Recent")}
            </div>
        </div>
    )
}
