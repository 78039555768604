import './mobile-footer-link.css'

const MobileFotterLink = ({link, text, t, i18n}: {link: string, text: string, t: any, i18n: any}) => {
    return (
        <a href={`/${link}`} className='mobile-footer-link' data-test-id={`${link}-nav-link`}>
            {t(text)}
        </a>
    )
}

export default MobileFotterLink