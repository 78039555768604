import { ReactComponent as NavLogo } from './logo.svg'
import './nav-bar-logo.css'


const NavBarLogo = () => {
    return (
        <div className="logo">
            <div className="logo-wrapper">
                <NavLogo />
            </div>
        </div>
    )
}

export default NavBarLogo;