import './swap-body.css'
import { ReactComponent as SwapArrowBottom } from './swap-arrow-bottom.svg'
import { ReactComponent as SwapArrowIcon } from './swap-arrow-icon.svg'
import { ReactComponent as GasIcon } from './gas-icon.svg'
import { ReactComponent as GasArrowDown } from './gas-arrow-down.svg'
import EthIcon from './usdc-logo.png'
import { useState } from 'react'
import { providerConnectParams } from '../..'

// interface IWalletData {
    
// }

interface ISelectTokenData {
    currency: string,
    currency_img: string,
    network: string,
    price: number,
    wallet: any
}

const SwapBody = ({t, i18n, networks, universalProvider, dt, setDt, fromTokenData, toTokenData, setFromTokenData, setToTokenData }: {t: any, i18n: any, networks: any, universalProvider: any, dt: number, setDt: any, fromTokenData: any, toTokenData: any, setFromTokenData: any, setToTokenData: any }) => {
    console.log("Swap body render")
    const a = dt
    return (
        <div className='swap-body'>
            <div className='swap-section-select'>
                <div id='swap-currency-input' className='swap-currency-input'>
                    <div className='swap-currency-input-panel-container'>
                        <SwapSectionSelect t={t} i18n={i18n} tokenData={fromTokenData} fromOrTo={"from"} />
                    </div>
                </div>
            </div>
            <SwapArrow setFromTokenData={setFromTokenData} setToTokenData={setToTokenData} fromTokenData={fromTokenData} toTokenData={toTokenData} />
            <SwpaSectionAuto t={t} i18n={i18n} networks={networks} universalProvider={universalProvider} toTokenData={toTokenData} />
        </div>
    )
}

export default SwapBody

const SwapSectionSelect = ({t, i18n, tokenData, fromOrTo}: {t: any, i18n: any, tokenData: any, fromOrTo: string}) => {
    const [searchString, setSearchString] = useState();

    const inputEventChange = (event: any) => {
        var e = event.target
        e.value = e.value.replace(/[^0-9.\.]/g, '').replace(/^(\d*\.?)|(\d*)\.?/g, "$1$2")
    }

    return (
        <div className='swap-currency-input-panel-container'>
            <span className='swap-title'>{fromOrTo == 'from' ? t("You pay") : t("You receive")}</span>
            <div className='swap-currency-input-panel-input-row'>
                <div className='numerical-input-wrapper'>
                    <input type="text" className='numerical-input' onInput={inputEventChange} placeholder='0' data-price={0} value={searchString} onChange={(e: any) => setSearchString(e.target.value)}
                    inputMode='decimal' autoComplete='off' autoCorrect='off' pattern='^[0-9]*[.,]?[0-9]*$' minLength={1} maxLength={79} spellCheck={false} />
                </div>
                <div>
                    {tokenData?<CurrencySelectWrapper tokenData={tokenData} fromOrTo={fromOrTo} />: <CurrencySelectWrapperDefault t={t} i18n={i18n} />}
                </div>
            </div>
            <SwapCurrencyFiatWrapper t={t} i18n={i18n} tokenData={tokenData} />
        </div>
    )
}

const SwapArrow = ({setFromTokenData, setToTokenData, fromTokenData, toTokenData}: {setFromTokenData: any, setToTokenData: any, fromTokenData: any, toTokenData: any}) => {

    const swapCurrencyClick = (event: any) => {
        var token_button_from = document.querySelector('.currency-select-button.from') as HTMLElement
        var token_button_to = document.querySelector('.currency-select-button.to') as HTMLElement
        var token_from_data: any = fromTokenData
        setFromTokenData(toTokenData);
        setToTokenData(token_from_data)        
        var token_from_input_value = (token_button_from.closest('.swap-currency-input-panel-input-row')?.querySelector('.numerical-input') as HTMLInputElement)?.value;
        var token_to_input_value = (token_button_to.closest('.swap-currency-input-panel-input-row')?.querySelector('.numerical-input') as HTMLInputElement)?.value;
        (token_button_from.closest('.swap-currency-input-panel-input-row')?.querySelector('.numerical-input') as HTMLInputElement).value = token_to_input_value;
        (token_button_to.closest('.swap-currency-input-panel-input-row')?.querySelector('.numerical-input') as HTMLInputElement).value = token_from_input_value;
    }

    return (
        <div className='arrow-wrapper'>
            <div data-test-id='swap-currency-button' onClick={swapCurrencyClick} className='arrow-container'>
                <SwapArrowIcon />
            </div>
        </div>
    )
}

const SwpaSectionAuto = ({t, i18n, networks, universalProvider, toTokenData }: {t: any, i18n: any, networks: any, universalProvider: any, toTokenData: any }) => {
    return (
        <div className='swap-section-auto'>
            <div>
                <div className='swap-section-output'>
                    <SwapSectionSelect t={t} i18n={i18n} tokenData={toTokenData} fromOrTo={"to"} />
                </div>
            </div>
            <div>
                <SwapConfirmButton t={t} i18n={i18n} universalProvider={universalProvider} />
                {/* <SwapDetailsWrapper t={t} i18n={i18n} /> */}
            </div>
        </div>
    )
}

const CurrencySelectWrapper = ({tokenData, fromOrTo}: {tokenData: any, fromOrTo: string}) => {

    const openTokenSelectModal = (event: any) => {
        var e = event.target
        if (!e.classList.contains('currency-select-button')) {e = e.closest('.currency-select-button')}
        var select_token_modal = document.querySelector('.select-token-modal') as HTMLElement
        if (select_token_modal?.dataset?.tokenSymbol || select_token_modal?.dataset?.tokenSymbol == '') {
            select_token_modal.dataset.tokenSymbol = e.querySelector('.currency-select-button.default .swap-currency-input-panel-symbol')? '': e.querySelector('.currency-select-button .swap-currency-input-panel-symbol').textContent
        }
        var second_token: any
        if (select_token_modal?.dataset?.selectTarget || select_token_modal?.dataset?.selectTarget == '') {
            if (e.classList.contains('from')) {
                select_token_modal.dataset.selectTarget = 'from'
                second_token = document.querySelector('.currency-select-button.to .swap-currency-input-panel-symbol')?.textContent
            }
            else {
                select_token_modal.dataset.selectTarget = 'to'
                second_token = document.querySelector('.currency-select-button.from .swap-currency-input-panel-symbol')?.textContent
            }
        }
        if (select_token_modal?.dataset?.selectBlank || select_token_modal?.dataset?.selectBlank == '') {
            if (e.classList.contains('default')) {
                select_token_modal.dataset.selectBlank = 'true'
            }
        }
        // var all_popular_tokens = select_token_modal.querySelectorAll('.popular-token-select')
        // all_popular_tokens.forEach((el: any) => {
        //     if (el.querySelector('.popular-token-select-symbol-text').textContent.replaceAll('\n', '').replaceAll(' ', '') == select_token_modal.dataset.tokenSymbol || el.querySelector('.popular-token-select-symbol-text').textContent.replaceAll('\n', '').replaceAll(' ', '') == second_token) {
        //         el.classList.add('selected')
        //     }
        //     else {
        //         el.classList.remove('selected')
        //     }
        // })
        // var all_tokens = select_token_modal.querySelectorAll('.token-list-element')
        // all_tokens.forEach((el: any) => {
        //     if (el.querySelector('.token-list-element-symbol').textContent.replaceAll('\n', '').replaceAll(' ', '') == select_token_modal.dataset.tokenSymbol || el.querySelector('.token-list-element-symbol').textContent.replaceAll('\n', '').replaceAll(' ', '') == second_token) {
        //         el.classList.add('selected')
        //     }
        //     else {
        //         el.classList.remove('selected')
        //     }
        //     el.style.display = 'flex'
        // })
        document.querySelector('body')?.classList.add('select-token-modal-open')
    }

    return (
        <div className='currecy-select-wrapper'>
            <button className={`currency-select-button ${fromOrTo}`} onClick={openTokenSelectModal}>
                <span className='swap-currency-input-panel-aligner'>
                    <div className='row-coin-data'>
                        <div className='assert-logo-container'>
                            <div className='portfolio-logo-container'>
                                <div className='single-logo-container'>
                                    <img src={tokenData.logoUrl} alt='logo' className='logo-circle' />
                                </div>
                            </div>
                        </div>
                        <span className='swap-currency-input-panel-symbol'>{tokenData.symbol}</span>
                    </div>
                    <SwapArrowBottom />
                </span>
            </button>
        </div>
    )
}

const CurrencySelectWrapperDefault = ({t, i18n}: {t: any, i18n: any}) => {
    const openTokenSelectModal = (event: any) => {
        var e = event.target
        if (!e.classList.contains('currency-select-button')) {e = e.closest('.currency-select-button')}
        var select_token_modal = document.querySelector('.select-token-modal') as HTMLElement
        if (select_token_modal?.dataset?.tokenSymbol || select_token_modal?.dataset?.tokenSymbol == '') {
            select_token_modal.dataset.tokenSymbol = e.querySelector('.currency-select-button.default .swap-currency-input-panel-symbol')? '': e.querySelector('.currency-select-button .swap-currency-input-panel-symbol').textContent
        }
        var second_token: any
        if (select_token_modal?.dataset?.selectTarget || select_token_modal?.dataset?.selectTarget == '') {
            if (e.classList.contains('from')) {
                select_token_modal.dataset.selectTarget = 'from'
                second_token = document.querySelector('.currency-select-button.to .swap-currency-input-panel-symbol')?.textContent
            }
            else {
                select_token_modal.dataset.selectTarget = 'to'
                second_token = document.querySelector('.currency-select-button.from .swap-currency-input-panel-symbol')?.textContent
            }
        }
        var all_popular_tokens = select_token_modal.querySelectorAll('.popular-token-select')
        all_popular_tokens.forEach((el: any) => {
            if (el.querySelector('.popular-token-select-symbol-text').textContent.replaceAll('\n', '').replaceAll(' ', '') == select_token_modal.dataset.tokenSymbol || el.querySelector('.popular-token-select-symbol-text').textContent.replaceAll('\n', '').replaceAll(' ', '') == second_token) {
                el.classList.add('selected')
            }
            else {
                el.classList.remove('selected')
            }
        })
        if (select_token_modal?.dataset?.selectBlank || select_token_modal?.dataset?.selectBlank == '') {
            if (e.classList.contains('default')) {
                select_token_modal.dataset.selectBlank = 'true'
            }
        }
        var all_tokens = select_token_modal.querySelectorAll('.token-list-element')
        all_tokens.forEach((el: any) => {
            if (el.querySelector('.token-list-element-symbol').textContent.replaceAll('\n', '').replaceAll(' ', '') == select_token_modal.dataset.tokenSymbol || el.querySelector('.token-list-element-symbol').textContent.replaceAll('\n', '').replaceAll(' ', '') == second_token) {
                el.classList.add('selected')
            }
            else {
                el.classList.remove('selected')
            }
            el.style.display = 'flex'
        })
        document.querySelector('body')?.classList.add('select-token-modal-open')
    }
    return (
        <div className='currecy-select-wrapper'>
            <button className='currency-select-button default to' onClick={openTokenSelectModal}>
                <span className='swap-currency-input-panel-aligner'>
                    <div className='row-coin-data'>
                        <span className='swap-currency-input-panel-symbol'>{`${t("Select token")}`}</span>
                    </div>
                    <SwapArrowBottom />
                </span>
            </button>
        </div>
    )
}

const SwapCurrencyFiatWrapper = ({t, i18n, tokenData}: {t: any, i18n: any, tokenData: any}) => {
    return (
        <div className='swap-currency-input-panel-fiat-row'>
            <div className='swap-row-beetwen'>
                <div className='loading-opacity-container'>
                    <div className='text-wrapper-container'>
                        <div className='fiat-value-swap-currency-input' data-test-id={'fiat-value-swap-currency-input'}>
                        </div>
                    </div>
                </div>
                <div className='balance-row-fixed'>
                    <div data-test-id='balance-text' className='blance-text-wrapper'>
                        {undefined?`${t("Balance")}: -`: ''}
                    </div>
                </div>
            </div>
        </div>
    )
}

const SwapSectionOutput = ({t, i18n}: {t: any, i18n: any}) => {
    return (
        <div className='swap-section-output'>
            <div className='swap-currency-input-panel-container'>
                <span className='swap-title'>{t("You pay")}</span>
                <div className='swap-currency-input-panel-input-row'>
                    <div className='numerical-input-wrapper'>
                        <input type="text" className='numerical-input' placeholder='0' value={''} />
                    </div>
                    <div>
                        <CurrencySelectWrapperDefault t={t} i18n={i18n} />
                    </div>
                </div>
                {/* <SwapCurrencyFiatWrapper t={t} i18n={i18n} /> */}
            </div>
        </div>
    )
}

const SwapConfirmButton = ({t, i18n, universalProvider}: {t: any, i18n: any, universalProvider: any}) => {
    
    const openConnectWallet = (event: any) => {
        document.querySelector('body')?.classList.add('connect-open')
    }
    
    return (
        <button className='swap-button' id='swap-button' disabled={universalProvider.session? true: false}  onClick={ async () => await universalProvider.connect(providerConnectParams)} >
            <div>
                {universalProvider.session? t("Select Token"): t('Connect wallet')}
            </div>
        </button>
    )
}

const SwapDetailsWrapper = ({t, i18n}: {t: any, i18n: any}) => {

    const openCloseCommisionWrapper = (event: any) => {
        var el = event.target.closest('.swap-details-wrapper')
        if (el.classList.contains('open')) {
            el.classList.remove('open')
        }
        else {
            el.classList.add('open')
        }
    }

    return (
        <div className='swap-details-wrapper'>
            <div className='swap-details-header-row' data-test-id="swap-details-header-row">
                <div className='swap-details-header-row-left'>
                    <div data-test-id="trade-price-container" className='trade-price-container'>
                        <button className='trade-price-change-currency'>
                            <div className='trade-price-text-wrapper'>
                                1 USDT = 0.00027 ETH 
                            </div>
                            <div className='trade-price-text-wrapper'>
                                {'($1.00)'}
                            </div>
                        </button>
                    </div>
                </div>
                <div className='swap-details-header-row-right'>
                    <div className='swap-commision-wrapper' onClick={openCloseCommisionWrapper}>
                        <div>
                            <div className='swap-gas-container'>
                                <div className='swap-gas-wrapper'>
                                    <GasIcon />
                                    <div className='swap-gas-price-container'>
                                        <div className='swap-gas-price-wrapper'>
                                            <div className='swap-gas-price-text-wrapper'>
                                                $6.51
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <GasArrowDown />
                    </div>
                </div>
            </div>
            <div className='swap-details-body'>
                <div>
                    <div className='advanced-swap-details' data-test-id='advanced-swap-details'>
                        <div>
                            <div className='advanced-swap-detail-li'>
                                <div data-test-id='swap-li-label' className='swap-li-label'>
                                    {t("Price impact")}
                                </div>
                                <div className='swap-li-info'>
                                    <div>
                                        <div className='swap-li-info-text-wrapper'>
                                            <span className='swap-li-info-text'>
                                                ~0.3%
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='advanced-swap-detail-li'>
                                <div data-test-id='swap-li-label' className='swap-li-label'>
                                    {t("Max")}. {t("slippage")}
                                </div>
                                <div className='swap-li-info'>
                                    <div>
                                        <div className='swap-li-info-text-wrapper with-label'>
                                            <div className='swap-li-info-text'>
                                            </div>
                                            0.5%
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='advanced-swap-detail-li'>
                                <div data-test-id='swap-li-label' className='swap-li-label'>
                                    {t('Fee')} {'(0.25%)'}
                                </div>
                                <div className='swap-li-info'>
                                    <div>
                                        <div className='swap-li-info-text-wrapper'>
                                            <span className='swap-li-info-text'>
                                                $9.28
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='advanced-swap-detail-li'>
                                <div data-test-id='swap-li-label' className='swap-li-label'>
                                    {t("Network cost")}
                                </div>
                                <div className='swap-li-info'>
                                    <div>
                                        <div className='swap-li-info-block-wrapper'>
                                            <div className='swap-li-info-block'>
                                                <div>
                                                    <div className='swap-li-info-text-loader'>
                                                        <div className='swap-li-info-loader'>
                                                            <GasIcon />
                                                            <div className='swap-li-info-text-wrapper'>
                                                                <div className='swap-li-info-text'>
                                                                    <div>$5.97</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='advanced-swap-detail-li'>
                                <div data-test-id='swap-li-label' className='swap-li-label'>
                                    {t("Order routing")}
                                </div>
                                <div className='swap-li-info'>
                                    <div>
                                        <div className='swap-li-info-text-wrapper'>
                                            <span className='swap-li-info-text'>
                                                Uniswap API
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
