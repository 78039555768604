import './buy-body.css'

const BuyBody = ({t, i18n}: {t: any, i18n: any}) => {
    return (
        <div className='buy-body'>
            <div className='buy-body-wrapper'>
                <div className='buy-body-top'>
                    <div className='buy-body-top-title'>
                        <span className='buy-body-top-title-wrapper'>
                            {t("You’re buying")}
                        </span>
                    </div>
                    <div className='buy-body-top-currency'>
                        <span className='buy-body-error'>
                            Something went wrong!
                            <br />
                            TypeError: Failed to fetch
                        </span>
                    </div>
                    <div></div>
                </div>
            </div>
        </div>
    )
}

export default BuyBody;