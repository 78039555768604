import './swap-menu-button.css'

const SwapMenuButton = ({text, t, i18n}: {text: string, t: any, i18n: any}) => {

    const openMenuBlock = (event: any) => {
        var el = event.target
        if (!el.classList.contains('swap-menu-button')) {
            el = el.classList.closest('.swap-menu-button')
        }
        var menu_name = el.classList[1]
        var swap_wrapper = document.querySelector('.swap-wrapper')
        switch (menu_name) {
            case 'swap':
                swap_wrapper?.classList.remove('limit')
                swap_wrapper?.classList.remove('send')
                swap_wrapper?.classList.remove('buy')
                swap_wrapper?.classList.add('swap')
                break;

            case 'limit':
                swap_wrapper?.classList.remove('swap')
                swap_wrapper?.classList.remove('send')
                swap_wrapper?.classList.remove('buy')
                swap_wrapper?.classList.add('limit')
                break;
                
            case 'send':
                swap_wrapper?.classList.remove('limit')
                swap_wrapper?.classList.remove('swap')
                swap_wrapper?.classList.remove('buy')
                swap_wrapper?.classList.add('send')
                break;
                
            case 'buy':
                swap_wrapper?.classList.remove('limit')
                swap_wrapper?.classList.remove('send')
                swap_wrapper?.classList.remove('swap')
                swap_wrapper?.classList.add('buy')
                break;
        
            default:
                swap_wrapper?.classList.remove('limit')
                swap_wrapper?.classList.remove('send')
                swap_wrapper?.classList.remove('buy')
                swap_wrapper?.classList.remove('swap')
                break;
        }
    }

    return (
        <button className={`swap-menu-button ${text.toLowerCase()}`} onClick={openMenuBlock}>
            {t(text)}
        </button>
    )
}

export default SwapMenuButton
