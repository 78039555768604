import './limit-body.css'
import {ReactComponent as NotEthAlertIcon} from './not-eth-alert-icon.svg'
import {ReactComponent as PolygonLogoIcon} from './polygon-logo-icon.svg'
import {ReactComponent as SwapLimitLogo} from './swap-limit-logo.svg'
import {ReactComponent as ArrowDownTokenSelect} from './arrow-down-token-select.svg'
import {ReactComponent as SwapLimitSecondLogo} from './arrow-down-token-select.svg'
import InchLogoIcon from './inch-logo.png'
import UsdcLogoIcon from './usdc-logo.png'

const LimitBody = ({t, i18n, networks}: {t: any, i18n: any, networks: any}) => {
    return (
        <div className='limit-body'>
            <LimitSectionHeader t={t} i18n={i18n} networks={networks} />
            <LimitSectionTop t={t} i18n={i18n} networks={networks} />
            <LimitSectionSwapper t={t} i18n={i18n} />
            <LimitSectionBottom t={t} i18n={i18n} networks={networks} />
            <LimitSectionExpiry t={t} i18n={i18n} />
            <button className='limit-section-confirm-button'>{t("Select supported tokens")}</button>
            <div className='limit-section-not-eth-alert'>
                <NotEthAlertIcon />
                <div className='limit-section-not-eth-alert-text'>
                    {t("Only Ethereum mainnet tokens are available for limits")}.
                    <a className='limit-section-not-eth-alert-link-more' href="">&nbsp;{t("Learn more")}</a>
                </div>
            </div>
        </div>
    )
}

export default LimitBody;


const LimitSectionHeader = ({t, i18n, networks}: {t: any, i18n: any, networks: any}) => {
    var selected_network: string = ''
    networks?.forEach((element: any)=> {
        if (element.active) {
            selected_network = element.img
        }
    });
    return (
        <div className='limit-section-header'>
            <div className='limit-section-header-wrapper'>
                <div className='limit-section-header-top-section'>
                    <span className='limit-section-header-top-section-left-side'>
                        <div className='limit-section-header-top-section-left-side-title'>
                            {t("When")} 1
                            <span className='limit-section-header-top-section-left-side-token'>
                                <div>
                                    <div className='limit-section-header-top-section-left-side-token-wrapper'>
                                        <div className='limit-section-header-top-section-left-side-token-image'>
                                            <div className='limit-section-header-top-section-left-side-token-image-wrapper'>
                                                <div className='limit-section-header-top-section-left-side-token-icon'>
                                                    <img src={InchLogoIcon} alt="" />
                                                </div>
                                                <div className='limit-section-header-top-section-left-side-token-network'>
                                                    {/* <img src={`http://localhost:1488${selected_network}`} alt='' /> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='limit-section-header-top-section-left-side-token-name'>
                                            1INCH
                                        </div>
                                    </div>
                                </div>
                            </span>
                            {t("is worth")}
                        </div>
                    </span>
                    <div className='limit-section-header-top-section-swap-icon'>
                        <SwapLimitLogo />
                    </div>
                </div>
                <div className='limit-section-header-center-section'>
                    <input className='limit-section-header-center-section-input' inputMode="decimal" autoComplete="off" pattern="^[0-9]*[.,]?[0-9]*$" placeholder='0' minLength={1} maxLength={79} spellCheck={false} autoCorrect='off' type='text' />
                    <div className='limit-section-header-center-section-token'>
                        <button className='limit-section-header-center-section-token-select'>
                            <div className='limit-section-header-center-section-token-wrapper'>
                                <div className='limit-section-header-center-section-token-image'>
                                    <div className='limit-section-header-center-section-token-image-wrapper'>
                                        <div className='limit-section-header-center-section-token-icon'>
                                            <img src={UsdcLogoIcon} alt="" />
                                        </div>
                                        <div className='limit-section-header-center-section-token-network'>
                                            {/* <img src={`http://localhost:1488${selected_network}`} alt='' /> */}
                                        </div>
                                    </div>
                                </div>
                                <div className='limit-section-header-center-section-token-name'>USDC</div>
                            </div>
                        </button>
                    </div>
                </div>
                <div className='limit-section-header-bottom-section'>
                    <div className='limit-section-header-center-section-wrapper'>
                        <div className='limit-section-header-center-section-market'>
                            <button className='limit-percent-select-button selected'>
                                <div className='limit-percent-select-text'>{t('Market')}</div>
                            </button>
                        </div>
                        <button className='limit-percent-select-button'>
                            <div className='limit-percent-select-text'>+1%</div>
                        </button>
                        <button className='limit-percent-select-button'>
                            <div className='limit-percent-select-text'>+5%</div>
                        </button>
                        <button className='limit-percent-select-button'>
                            <div className='limit-percent-select-text'>+10%</div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const LimitSectionTop = ({t, i18n, networks}: {t: any, i18n: any, networks: any}) => {
    var selected_network: string = ''
    networks?.forEach((element: any)=> {
        if (element.active) {
            selected_network = element.img
        }
    });
    return (
        <div className='limit-section-top'>
            <div id='limit-currency-input' className='limit-currency-input'>
                <div className='limit-currency-input-wrapper'>
                    <span className='limit-currency-input-title'>{t("Sell")}</span>
                    <div className='limit-currency-input-section'>
                        <div className='limit-currency-input-section-input-wrapper'>
                            <input className='limit-currency-input-section-input' inputMode="decimal" autoComplete="off" autoCorrect="off" pattern="^[0-9]*[.,]?[0-9]*$" placeholder="0" minLength={1} maxLength={79} spellCheck={false} type="text" />
                        </div>
                        <div>
                            <div className='limit-currency-input-section-token'>
                                <button className='limit-currency-input-section-token-select'>
                                    <span className='limit-currency-input-section-token-select-wrapper'>
                                        <div className='limit-currency-input-section-token-icon'>
                                            <div className='limit-currency-input-section-token-icon-wrapper'>
                                                <div className='limit-currency-input-section-token-icon-wrapper-second'>
                                                    <div className='limit-currency-input-section-token-image'>
                                                        <img src={InchLogoIcon} alt="" />
                                                    </div>
                                                    <div className='limit-currency-input-section-token-network'>
                                                        {/* <img src={`http://localhost:1488${selected_network}`} alt='' /> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <span className='limit-currency-input-section-token-symbol'>1INCH</span>
                                        </div>
                                        <ArrowDownTokenSelect className='limit-token-select-arrow' />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='limit-currency-input-bottom'>
                        <div className='limit-currency-input-bottom-wrapper'>
                            <div className='limit-currency-input-bottom-price'>
                                <div className='limit-currency-input-bottom-text'>
                                    $0.01
                                </div>
                            </div>
                            <div className='limit-currency-input-bottom-balance'>
                                <div className='limit-currency-input-bottom-text'>
                                    {t("Balance")}: 0
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const LimitSectionBottom = ({t, i18n, networks}: {t: any, i18n: any, networks: any}) => {
    var selected_network: string = ''
    networks?.forEach((element: any)=> {
        if (element.active) {
            selected_network = element.img
        }
    });
    return (
        <div className='limit-section-bottom'>
            <div id='limit-currency-input' className='limit-currency-input'>
                <div className='limit-currency-input-wrapper'>
                    <span className='limit-currency-input-title'>{t("Buy")}</span>
                    <div className='limit-currency-input-section'>
                        <div className='limit-currency-input-section-input-wrapper'>
                            <input className='limit-currency-input-section-input' inputMode="decimal" autoComplete="off" autoCorrect="off" pattern="^[0-9]*[.,]?[0-9]*$" placeholder="0" minLength={1} maxLength={79} spellCheck={false} type="text" />
                        </div>
                        <div>
                            <div className='limit-currency-input-section-token'>
                                <button className='limit-currency-input-section-token-select'>
                                    <span className='limit-currency-input-section-token-select-wrapper'>
                                        <div className='limit-currency-input-section-token-icon'>
                                            <div className='limit-currency-input-section-token-icon-wrapper'>
                                                <div className='limit-currency-input-section-token-icon-wrapper-second'>
                                                    <div className='limit-currency-input-section-token-image'>
                                                        <img src={InchLogoIcon} alt="" />
                                                    </div>
                                                    <div className='limit-currency-input-section-token-network'>
                                                        {/* <img src={`http://localhost:1488${selected_network}`} alt='' /> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <span className='limit-currency-input-section-token-symbol'>1INCH</span>
                                        </div>
                                        <ArrowDownTokenSelect className='limit-token-select-arrow' />
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='limit-currency-input-bottom'>
                        <div className='limit-currency-input-bottom-wrapper'>
                            <div className='limit-currency-input-bottom-price'>
                                <div className='limit-currency-input-bottom-text'>
                                    $0.01
                                </div>
                            </div>
                            <div className='limit-currency-input-bottom-balance'>
                                <div className='limit-currency-input-bottom-text'>
                                    {t("Balance")}: 0
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const LimitSectionSwapper = ({t, i18n}: {t: any, i18n: any}) => {
    return (
        <div className='limit-section-swapper'>
            <div className='limit-section-swapper-wrapper'>
                <SwapLimitSecondLogo />
            </div>
        </div>
    )
}

const LimitSectionExpiry = ({t, i18n}: {t: any, i18n: any}) => {
    return (
        <div className='limit-section-expiry'>
            <div className='limit-section-expiry-title'>
                {t("Expiry")}
            </div>
            <div className='limit-section-expiry-body'>
                <button className='limit-section-expiry-button'>
                    <div className='limit-section-expiry-period'>
                        1 {t("day")}
                    </div>
                </button>
                <button className='limit-section-expiry-button selected'>
                    <div className='limit-section-expiry-period'>
                        1 {t("week")}
                    </div>
                </button>
                <button className='limit-section-expiry-button'>
                    <div className='limit-section-expiry-period'>
                        1 {t("month")}
                    </div>
                </button>
                <button className='limit-section-expiry-button'>
                    <div className='limit-section-expiry-period'>
                        1 {t("year")}
                    </div>
                </button>
            </div>
        </div>
    )
}
