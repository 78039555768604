import './nav-bar-connect-wallet.css'
import { ReactComponent as UserIcon } from './user-icon.svg'
import { providerConnectParams, universalProvider } from '../..'
import { Component, useState } from 'react';
import React from 'react';
import { useQuery } from 'wagmi/query';

const NavBarConnectWallet = ({t, i18n, universalProvider, walletConnectModal }: { t: any, i18n: any, universalProvider: any, walletConnectModal: any }) => {
    // const closeWalletConnect = (event: any) => {
    //     var el = event.target;
    //     var el1 = el.closest('.connection-drawer')
    //     var el2 = el.closest('.close-connect-wallet')
    //     if (el1 == null) {
    //         document.querySelector('body')?.classList.remove('connect-open')
    //         window.removeEventListener('click', closeWalletConnect)
    //     }
    //     else if (el2 !== null) {
    //         document.querySelector('body')?.classList.remove('connect-open')
    //         window.removeEventListener('click', closeWalletConnect)
    //     }
    // }
    return (
        <div>
            {universalProvider.session? <ConnectedWalletDiv t={t} universalProvider={universalProvider} />: <ConnectButton t={t} universalProvider={universalProvider} />}
        </div>
    )
}

type TNavBarConnectWallet = {
    t: any;
    i18n: any;
    universalProvider: any;
    walletConnectModal: any;
}

export class CNavBarConnectWallet extends Component<TNavBarConnectWallet, {value: number}> {
    setSomeVar: any;
    constructor(props: any) {
        super(props);
        this.state = {
            value: 0,
        };
        this.useForceUpdate = this.useForceUpdate.bind(this);
    }

    useForceUpdate() {
        this.setState({
            value: this.state.value + 1 
        });
        var swbut = document.querySelector('#swap-button') as HTMLElement;
        var token_from = document.querySelector('.currency-select-button.from') as HTMLElement
        var token_to = document.querySelector('.currency-select-button.to') as HTMLElement
        var token_to_input = token_to.closest('.swap-currency-input-panel-input-row')?.querySelector('.numerical-input') as HTMLInputElement
        var token_from_input = token_from.closest('.swap-currency-input-panel-input-row')?.querySelector('.numerical-input') as HTMLInputElement
        if (this.props.universalProvider.session) {
            if (token_from.classList.contains('default') || token_to.classList.contains('default')) {
                swbut.setAttribute('disabled', 'true');
                (swbut.querySelector("div") as HTMLElement).textContent = 'Select token'
            }
            else if (token_to_input.value.length != 0 || token_from_input.value.length != 0) {
                swbut.setAttribute('disabled', 'true');
                (swbut.querySelector("div") as HTMLElement).textContent = 'Enter amount'
            }
            else {
                swbut.setAttribute('disabled', 'true');
                (swbut.querySelector("div") as HTMLElement).textContent = 'Swap'
            }
        }
        else {
            swbut.removeAttribute('disabled');
            (swbut.querySelector("div") as HTMLElement).textContent = 'Connect wallet'
        }
    }

    render(): React.ReactNode {
        return (
            <div id='connect-wallet-button' onClick={this.useForceUpdate}>
                {universalProvider.session? <ConnectedWalletDiv t={this.props.t} universalProvider={this.props.universalProvider} />: <ConnectButton t={this.props.t} universalProvider={this.props.universalProvider} />}
            </div>
        )
    }
}

const ConnectButton = ({t, universalProvider}: {t: any, universalProvider: any}) => {
    return (
        <div className="connect-button-wrapper">
            <button className="connect-button" onClick={ async () => await universalProvider.connect(providerConnectParams)}>
                {t("Connect")}
            </button>
        </div>
    )
}

export const ConnectedWalletDiv = ({t, universalProvider}: {t: any, universalProvider: any}) => {
    const compareAddress = (val: string) => {
        var dt = ''
        dt += val[0] + val[1] + val[2] + val[3] + val[4] + val[5] + '...' + val[val.length-4] + val[val.length-3] + val[val.length-2] + val[val.length-1]
        return dt
    }

    const openModalConnectedWallet = () => {
        (document.querySelector('body') as HTMLElement).classList.add('connected-wallet-modal-open')
    }

    return (
        <button className='connected-button' onClick={openModalConnectedWallet}>
            <div className='connected-button-img'>
                <div className='connected-button-img-wrapper'>
                    <div className='connected-button-img-wrapper-second'>
                        <div className='connected-button-img-third'>
                            <UserIcon />
                        </div>
                    </div>
                </div>
            </div>
            <div className='connect-button-text'>
                <span className='connect-button-text-wrapper'>
                    {compareAddress(universalProvider.session.namespaces.eip155.accounts[0].replace(/eip155:[1-9]{1,}:/g, ''))}
                </span>
            </div>
        </button>
    )
}

export default NavBarConnectWallet;