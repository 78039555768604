import './mobile-search-bar.css'
import {ReactComponent as SearchIconMedium} from './mobile-search-bor-icon-medium.svg'
import {ReactComponent as SearchIconSmall} from './mobile-search-bar-icon-small.svg'
import MobileSearchBody from './mobile-search-body'

const MobileSearchBar = ({t, i18n}: {t: any, i18n: any}) => {
    const closeMobileSearchBar = (event: any) => {
        document.querySelector('.mobile-serach-wrapper.open')?.classList.remove('open')
        window.removeEventListener('click', mobileSearchBarOpen)
    }
    const mobileSearchBarOpen = (event: any) => {
        var el = event.target;
        el = el.closest('.mobile-serach-wrapper')
        if (el == null) {
            document.querySelector('.mobile-serach-wrapper.open')?.classList.remove('open');
            window.removeEventListener('click', mobileSearchBarOpen)
        }
    }
    return (
        <div className='mobile-search-bar'>
            <div className='mobile-search-bar-wrapper'>
                <div className='mobile-search-bar-left-icon-wrapper'>
                    <div className='mobile-search-bar-left-icon-medium'>
                        <SearchIconMedium />
                    </div>
                    <div className='mobile-search-bar-left-icon-small' onClick={closeMobileSearchBar}>
                        <SearchIconSmall />
                    </div>
                </div>
                <input type="text" placeholder='Search' className='mobile-search-bar-input' />
                <div className='mobile-search-bar-right-icon-wrapper'>/</div>
            </div>
            <MobileSearchBody t={t} i18n={i18n} />
        </div>
    )
}

export default MobileSearchBar;
