import FooterSocialLink from '../footer-social-link/footer-social-link'
import NavMoreMenuRow from '../nav-more-menu-wrapper/nav-more-menu-wrapper'
import './nav-more-menu.css'
import { ReactComponent as UniswapLogo } from './uniswap-logo-icon.svg'

const NavMoreMenu = ({t, i18n}: {t: any, i18n: any}) => {
    return (
        <div className='nav-more-menu' data-test-id='nav-more-menu'>
            <div className='more-menu-column'>
                <NavMoreMenuRow name='app' t={t} i18n={i18n} />
                <NavMoreMenuRow name='company' t={t} i18n={i18n} />
                <NavMoreMenuRow name='protocol' t={t} i18n={i18n} />
                <NavMoreMenuRow name='help' t={t} i18n={i18n} />
                <div className='more-menu-separator'></div>
                <div className='more-menu-download-uniswap'>
                    <div className='mobile-app-logo'>
                        <UniswapLogo />
                    </div>
                    <div className='mobile-app-download-text-wrapper'>
                        <div className='mobile-app-download-main-text'>
                            {t("Download Uniswap")}
                        </div>
                        <div className='mobile-app-download-second-text'>
                            {t("Available on iOS and Android")}
                        </div>
                    </div>
                </div>
                <div className='generic-box'>
                    <FooterSocialLink type='github' />
                    <FooterSocialLink type='twitter' />
                    <FooterSocialLink type='discord' />
                </div>
            </div>
        </div>
    )
}

export default NavMoreMenu