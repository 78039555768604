import { ReactComponent as SearchSvg } from './search-icon.svg';
import './nav-bar-search-button.css'


export const NavBarSearchButton = ({ tp }: { tp: string }) => {
    const openMobileSearchBar = (event: any) => {
        var el = event.target;
        el = el.closest('.mobile-search-bar')
        var el2 = event.target.closest('.mobile-serach-wrapper') 
        if (el !== null && document.querySelector('.mobile-serach-wrapper')?.classList.contains('open')) {
            document.querySelector('.mobile-serach-wrapper.open')?.classList.remove('open')
        }
        else if (el2 !== null && !el2.classList.contains('open')) {
            el2.classList.add('open')
            window.addEventListener('click', mobileSearchBarOpen)
        }
    }
    const mobileSearchBarOpen = (event: any) => {
        var el = event.target;
        el = el.closest('.mobile-serach-wrapper')
        if (el == null) {
            document.querySelector('.mobile-serach-wrapper.open')?.classList.remove('open');
            window.removeEventListener('click', mobileSearchBarOpen)
        }
    }
    return (
        <button className="search-button-mobile" onClick={openMobileSearchBar}>
            <SearchSvg />
        </button>
    )
}

export default NavBarSearchButton