import SwapWrapper from '../swap-wrapper/swap-wrapper'
import './page-wrapper.css'

const PageWrapper = ({t, i18n, networks, universalProvider, dt, setDt, fromTokenData, toTokenData, setFromTokenData, setToTokenData}: {t: any, i18n: any, networks: any, universalProvider: any, dt: number, setDt: any, fromTokenData: any, toTokenData: any, setFromTokenData: any, setToTokenData: any}) => {
    return (
        <div className='page-wrapper'>
            <main className='main-wrapper'>
                <SwapWrapper t={t} i18n={i18n} networks={networks} universalProvider={universalProvider} dt={dt} setDt={setDt} fromTokenData={fromTokenData} toTokenData={toTokenData}
                 setFromTokenData={setFromTokenData} setToTokenData={setToTokenData}/>
            </main>
        </div>
    )
}

export default PageWrapper