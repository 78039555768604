import { web3Provider } from '../..';
import BlockNumber from '../block-number-block/block-number-block';
import LanguageSelectWrapper from '../language-select-wrapper/language-select-wrapper';
import PageWrapper from '../page-wrapper/page-wrapper';
import './app-body.css'

const AppBody = ({t, i18n, networks, universalProvider, web3Provider, dt, setDt, fromTokenData, toTokenData, setFromTokenData, setToTokenData}: {t: any, i18n: any, networks: any, universalProvider: any, web3Provider: any, dt: number, setDt: any, fromTokenData: any, toTokenData: any, setFromTokenData: any, setToTokenData: any}) => {
    return (
        <div className='app-body'>
            <BlockNumber web3Provider={web3Provider} />
            <PageWrapper t={t} i18n={i18n} networks={networks} universalProvider={universalProvider} dt={dt} setDt={setDt} fromTokenData={fromTokenData} toTokenData={toTokenData}
             setFromTokenData={setFromTokenData} setToTokenData={setToTokenData} />
            <LanguageSelectWrapper t={t} i18n={i18n} />
        </div>
    )
}

export default AppBody;
