import './swap-header.css'
import { ReactComponent as SettingsSvg } from './settings-icon.svg'
import { ReactComponent as SettingInfoIcon } from './settings-info-icon.svg'
import { ReactComponent as SettingArrowDownIcon } from './settings-arrow-icon.svg'
import SwapMenuButton from '../swap-menu-button/swap-menu-button'

const SwapHeader = ({t, i18n}: {t: any, i18n: any}) => {
    const openExpandedSetting = (event: any) => {
        var el = event.target
        if (!el.classList.contains('swap-settings-dialog-row')) {el = el.closest('.swap-settings-dialog-row')}
        if (el.classList.contains('expanded')) {
            el.classList.remove('expanded')
        }
        else {
            el.classList.add('expanded')
        }
    }

    const swapSettingsTogle = (event: any) => {
        var el = event.target
        if (!el.classList.contains('swap-settings-trade-option-toggle')) {el = el.closest('.swap-settings-trade-option-toggle')}
        if (el.ariaSelected === 'true') {el.ariaSelected = 'false'; document.querySelector('.swap-settings-dialog-second')?.classList.add('trade-options-false')}
        else {
            el.ariaSelected = 'true';
            document.querySelector('.swap-settings-dialog-second')?.classList.remove('trade-options-false');
            (document.querySelector('#swap-settings-v3') as HTMLButtonElement).disabled = false;
            (document.querySelector('#swap-settings-v3') as HTMLButtonElement).ariaSelected = 'true';
            (document.querySelector('#swap-settings-v2') as HTMLButtonElement).disabled = false;
            (document.querySelector('#swap-settings-v2') as HTMLButtonElement).ariaSelected = 'true';
        }
    }

    const swapSettingsTogle2 = (event: any) => {
        var el = event.target
        var swapSettingsV3 = document.querySelector('#swap-settings-v3') as HTMLButtonElement
        var swapSettingsV2 = document.querySelector('#swap-settings-v2') as HTMLButtonElement
        if (!el.classList.contains('swap-settings-trade-option-toggle')) {el = el.closest('.swap-settings-trade-option-toggle')}
        if (el === swapSettingsV2) {
            if (el.ariaSelected === 'false') {
                el.ariaSelected = 'true'
                swapSettingsV3.disabled = false
            }
            else if (swapSettingsV3.disabled === false) {
                el.ariaSelected = 'false'
                swapSettingsV3.disabled = true
            }
        }
        else {
            if (el.ariaSelected === 'false') {
                el.ariaSelected = 'true'
                swapSettingsV2.disabled = false
            }
            else if (swapSettingsV2.disabled === false) {
                el.ariaSelected = 'false'
                swapSettingsV2.disabled = true
            }
        }
    }

    const openCloseSwapSettings = (event: any) => {
        var el = event.target
        if (!el.classList.contains('settings-menu')) {
            el = el.closest('.settings-menu')
        }
        if (el.classList.contains('open')) {
            el.classList.remove('open')
        }
        else {
            el.classList.add('open')
            setTimeout(() => {window.addEventListener('click', windowCloseSwapSettings)}, 100)
        }
    }    

    const windowCloseSwapSettings = (event: any) => {
        var el = event.target
        if (!el.classList.contains('settings-menu')) {
            el = el.closest('.settings-menu')
        }
        if (el === null) {
            document.querySelector('.settings-menu')?.classList.remove('open')
            window.removeEventListener('click', windowCloseSwapSettings)
        }
    }

    const swapCustomSlipageInput = (event: any) => {
        var auto_or_custom = document.querySelectorAll('.swap-settings-dialog-expanded-select')
        var text_block = (document.querySelector('.swap-settings-dialog-row-right-text') as HTMLElement)
        var el = event.target;
        el.value = el.value.replace(/[|&;$%@"<>()+*/!`~?><'^_\-=[\]\\{},a-zA-Z]/g, "")
        if (parseFloat(el.value) !== 0 && el.value.length > 0 && el.value.length <= 5) {
            auto_or_custom[0].classList.remove('selected')
            auto_or_custom[1].classList.add('selected')
            text_block.textContent = String(parseFloat(el.value).toFixed(2)) + '%'
        }
        else {
            auto_or_custom[0].classList.add('selected')
            auto_or_custom[1].classList.remove('selected')
            text_block.textContent = 'Auto'
        }
    }

    const swapCustomSlipage = (event: any) => {
        var auto_or_custom = document.querySelectorAll('.swap-settings-dialog-expanded-select')
        auto_or_custom[0].classList.remove('selected')
        auto_or_custom[1].classList.add('selected')
        var text_block = (document.querySelector('.swap-settings-dialog-row-right-text') as HTMLElement)
        text_block.textContent = '0.5%'
    }

    const swapAutoSlipage = (event: any) => {
        var auto_or_custom = document.querySelectorAll('.swap-settings-dialog-expanded-select')
        auto_or_custom[0].classList.add('selected')
        auto_or_custom[1].classList.remove('selected')
        var swap_custom_input = (document.querySelector('.swap-settings-dialog-expanded-input') as HTMLInputElement);
        swap_custom_input.value = '';
        var text_block = (document.querySelector('.swap-settings-dialog-row-right-text') as HTMLElement)
        text_block.textContent = 'Auto'
    }

    const swapDeadlineInput = (event: any) => {
        var swapDeadlineText = (document.querySelectorAll('.swap-settings-dialog-row-right-text')[1] as HTMLElement)
        var el = event.target;
        el.value = el.value.replace(/[|&;$%@"<>()+*/!`~?><'^._\-=[\]\\{},a-zA-Z]/g, "")
        if (el.value.length > 0) {
            swapDeadlineText.textContent = el.value + t('m')
        }
        else {
            swapDeadlineText.textContent = '10' + t('m')
        }
    }

    return (
        <div className='swap-header'>
            <div className='swap-header-left'>
                <SwapMenuButton text={'Swap'} t={t} i18n={i18n} />
                <SwapMenuButton text={'Limit'} t={t} i18n={i18n} />
                <SwapMenuButton text={'Send'} t={t} i18n={i18n} />
                <SwapMenuButton text={'Buy'} t={t} i18n={i18n} />
            </div>
            <div className='swap-header-right'>
                <div className='settings-menu'>
                    <button id='open-settings-dialog-button' onClick={openCloseSwapSettings} className='settings-menu-button'>
                        <div className='settings-menu-icon'>
                            <SettingsSvg />
                        </div>
                    </button>
                    <div className='swap-settings-dialog'>
                        <div className='swap-settings-dialog-first'>
                            <div>
                                <div className='swap-settings-dialog-grid'>
                                    <div className='swap-settings-dialog-row'>
                                        <div className='swap-settings-dialog-row-wrapper'>
                                            <div className='swap-settings-dialog-row-left'>
                                                <div className='swap-settings-dialog-row-left-text'>
                                                    {t("Max")}. {t("slippage")}
                                                </div>
                                                <span className='swap-settings-dialog-row-left-icon-wrapper'>
                                                    <div className='swap-settings-dialog-row-left-icon-wrapper-second'>
                                                        <div>
                                                            <div className='swap-settings-dialog-row-left-icon-wrapper-third'>
                                                                <span className='swap-settings-dialog-row-left-icon-wrapper-fourth'>
                                                                    <SettingArrowDownIcon />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                            <div className='swap-settings-dialog-row-right' onClick={openExpandedSetting} data-testid="max-slippage-settings" aria-expanded='false'>
                                                <div className='swap-settings-dialog-row-right-text'>
                                                    {t("Auto")}
                                                </div>
                                                <SettingInfoIcon />
                                            </div>
                                        </div>
                                        <div className='swap-setting-dialog-row-expanded'>
                                            <div>
                                                <div className='swap-setting-dialog-expanded-wrapper'>
                                                    <div className='swap-setting-dialog-expanded-row'>
                                                        <div className='swap-setting-dialog-expanded-left'>
                                                            <div className='swap-settings-dialog-expanded-select selected' onClick={swapAutoSlipage}>
                                                                <div className='swap-settings-dialog-expanded-select-text'>
                                                                    {t("Auto")}
                                                                </div>
                                                            </div>
                                                            <div className='swap-settings-dialog-expanded-select' onClick={swapCustomSlipage}>
                                                                <div className='swap-settings-dialog-expanded-select-text'>
                                                                    {t("Custom")}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='swap-setting-dialog-expanded-right'>
                                                            <input className='swap-settings-dialog-expanded-input' onInput={swapCustomSlipageInput} type="text" placeholder='0.5' />
                                                            <div className=''>%</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='swap-settings-dialog-row'>
                                        <div className='swap-settings-dialog-row-wrapper'>
                                            <div className='swap-settings-dialog-row-left'>
                                                <div className='swap-settings-dialog-row-left-text'>
                                                    {t("Transaction deadline")}
                                                </div>
                                                <span className='swap-settings-dialog-row-left-icon-wrapper'>
                                                    <div className='swap-settings-dialog-row-left-icon-wrapper-second'>
                                                        <div>
                                                            <div className='swap-settings-dialog-row-left-icon-wrapper-third'>
                                                                <span className='swap-settings-dialog-row-left-icon-wrapper-fourth'>
                                                                    <SettingArrowDownIcon />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                            <div className='swap-settings-dialog-row-right' onClick={openExpandedSetting} data-testid="max-slippage-settings" aria-expanded='false'>
                                                <div className='swap-settings-dialog-row-right-text'>
                                                    10{t("m")}
                                                </div>
                                                <SettingInfoIcon />
                                            </div>
                                        </div>
                                        <div className='swap-setting-dialog-row-expanded'>
                                            <div>
                                                <div className='swap-setting-dialog-expanded-wrapper'>
                                                    <div className='swap-setting-dialog-expanded-row'>
                                                        <div className='swap-setting-dialog-expanded-right'>
                                                            <input className='swap-settings-dialog-expanded-input' onChange={swapDeadlineInput} type="text" placeholder='10' />
                                                            <div className=''>{t("minutes")}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='swap-settings-line'></div>
                        <div className='swap-settings-dialog-second'>
                            <div className='swap-settings-trade-options'>
                                <div className='swap-settings-trade-option-text-wrapper'>
                                    <div className='swap-settings-trade-option-title'>
                                        {t("Default trade options")}
                                    </div>
                                    <div className='swap-settings-trade-option-text'>
                                        {t("The Uniswap client selects the cheapest trade option factoring price and network costs")}.
                                    </div>
                                </div>
                                <button className='swap-settings-trade-option-toggle' onClick={swapSettingsTogle} aria-selected={true}>
                                    <span className='swap-settings-trade-option-toggler'></span>
                                </button>
                            </div>
                            <div className='swap-settings-trade-options'>
                                <div className='swap-settings-trade-option-text-wrapper'>
                                    <div className='swap-settings-trade-option-title'>
                                        v3 {t("pools")}
                                    </div>
                                </div>
                                <button className='swap-settings-trade-option-toggle' disabled={false} id='swap-settings-v3' onClick={swapSettingsTogle2} aria-selected={true}>
                                    <span className='swap-settings-trade-option-toggler'></span>
                                </button>
                            </div>
                            <div className='swap-settings-trade-options'>
                                <div className='swap-settings-trade-option-text-wrapper'>
                                    <div className='swap-settings-trade-option-title'>
                                        v2 {t("pools")}
                                    </div>
                                </div>
                                <button className='swap-settings-trade-option-toggle' disabled={false} id='swap-settings-v2' onClick={swapSettingsTogle2} aria-selected={true}>
                                    <span className='swap-settings-trade-option-toggler'></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SwapHeader
