import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from './assets/i18next/locale/en.json'
import translationRU from './assets/i18next/locale/ru.json'
import detector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";

const resources = {
    en: {
        translation: translationEN
    },
    ru: {
        translation: translationRU
    }
};


i18n
    .use(detector)
    .use(backend)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: "en",
        keySeparator: false,
        interpolation: {
            espaceValue: false,
        },
        react: {
            wait: true,
        },
    });
export default i18n;