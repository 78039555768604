import NavBarLinkArrow from "../nav-bar-link-arrow/nav-bar-link-arrow";
import NavBarLink from "../nav-bar-link/nav-bar-link";
import './nav-bar-links.css'


const NavBarLinks = ({tp, t, i18n}: {tp: string, t: any, i18n: any}) => {
    return (
        <div className="links-block">
            <NavBarLink active={true} text={"Swap"} t={t} i18n={i18n} />
            <NavBarLink active={false} text={"Explore"} t={t} i18n={i18n} />
            <NavBarLink active={false} text={"NFTs"} t={t} i18n={i18n} />
            <NavBarLinkArrow t={t} i18n={i18n} />
        </div>
    )
}

export default NavBarLinks;