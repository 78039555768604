import MobileFotterLink from '../mobile-footer-link/mobile-footer-link';
import NavMoreMenu from '../nav-more-menu/nav-more-menu';
import './app-footer.css'
import { ReactComponent as ArrowTop } from './arrow-top-icon.svg'

const AppFooter = ({t, i18n}: {t: any, i18n: any}) => {
    const openMoreMenu = (event: any) => {
        var el = event.target
        if (el != null) {
            var el1 = el.closest('.nav-more-menu')
            if (el1 == null) {
                if (!el.classList.contains('more-wrapper')) {
                    el = el.closest('.more-wrapper')
                }
                if (el.classList.contains('open')) {
                    el.classList.remove('open')
                    window.addEventListener('click', closeMoreMenu)
                }
                else {
                    el.classList.add('open')
                    setTimeout(() => {window.addEventListener('click', closeMoreMenu)}, 100)
                }
            }
        }
    }

    const closeMoreMenu = (event: any) => {
        var el = event.target
        if (!el.classList.contains('nav-more-menu')) {
            el = el.closest('.nav-more-menu')
        }
        if (el == null) {
            document.querySelector('.more-wrapper.open')?.classList.remove('open')
            window.removeEventListener('click', closeMoreMenu)   
        }
    }
    
    return (
        <div className='app-mobile-footer'>
            <MobileFotterLink link='swap' text='Swap' t={t} i18n={i18n} />
            <MobileFotterLink link='explore' text='Explore' t={t} i18n={i18n} />
            <MobileFotterLink link='nfts' text='NFTs' t={t} i18n={i18n} />
            <div className='app-mobile-footer-pool-wrapper'>
                <MobileFotterLink link='pool' text='Pool' t={t} i18n={i18n} />
            </div>
            <div className='more-wrapper' onClick={openMoreMenu}>
                <div className='nav-more-button' data-test-id='nav-more-button'>
                    <ArrowTop />
                </div>
                <NavMoreMenu t={t} i18n={i18n} />
            </div>
        </div>
    )
}

export default AppFooter;