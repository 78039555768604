import './app-header.css'
import NavBar from '../nav-bar/nav-bar';
import { ListFormat } from 'typescript';

const AppHeader = ({t, i18n, networks, universalProvider, walletConnectModal, dt, setDt, setToTokenData, setFromTokenData, currentNetwork, setCurrentNetwork, setTokens}: {t: any, i18n: any, networks: any, universalProvider: any, walletConnectModal: any, dt: number, setDt: any, setToTokenData: any, setFromTokenData: any, currentNetwork: any, setCurrentNetwork: any, setTokens: any}) => {
    // window.addEventListener('scroll', () => {
    //     console.log(window.scrollY)
    //     if (window.scrollY > 0) {
    //         if (!document.querySelector('.app-header')?.classList.contains('after-zero')) {
    //             document.querySelector('.app-header')?.classList.add('after-zero')
    //         }
    //     }
    //     else {
    //         if (document.querySelector('.app-header')?.classList.contains('after-zero')) {
    //             document.querySelector('.app-header')?.classList.remove('after-zero')
    //         }
    //     }
    // })
    return (
        <span className='_dsp_contents'>
            <span className='_dsp_contents2 t_dark'>
                <div className='app-header'>
                    <NavBar t={t} i18n={i18n} networks={networks} universalProvider={universalProvider} walletConnectModal={walletConnectModal} dt={dt} setDt={setDt} setFromTokenData={setFromTokenData} setToTokenData={setToTokenData} currentNetwork={currentNetwork} setCurrentNetwork={setCurrentNetwork} setTokens={setTokens} />
                </div>
            </span>
        </span>
    )
}

export default AppHeader;