import SwapBody from '../swap-body/swap-body'
import SwapHeader from '../swap-header/swap-header'
import './swap-wrapper.css'
import LimitBody from '../limit-body/limit-body'
import SendBody from '../send-body/send-body'
import BuyBody from '../buy-body/buy-body'


const SwapWrapper = ({t, i18n, networks, universalProvider, dt, setDt, fromTokenData, toTokenData, setFromTokenData, setToTokenData}: {t: any, i18n: any, networks: any, universalProvider: any, dt: number, setDt: any, fromTokenData: any, toTokenData: any, setFromTokenData: any, setToTokenData: any}) => {
    return (
        <div className='swap-wrapper swap'>
            <SwapHeader t={t} i18n={i18n} />
            <SwapBody t={t} i18n={i18n} networks={networks} universalProvider={universalProvider} dt={dt} setDt={setDt} fromTokenData={fromTokenData} toTokenData={toTokenData} 
             setFromTokenData={setFromTokenData} setToTokenData={setToTokenData}/>
            <LimitBody t={t} i18n={i18n} networks={networks} />
            <SendBody t={t} i18n={i18n} networks={networks} />
            <BuyBody t={t} i18n={i18n} />
        </div>
    )
}

export default SwapWrapper