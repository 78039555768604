import './footer-social-link.css'
import {ReactComponent as GithubIcon} from './github-icon.svg'
import {ReactComponent as DiscordIcon} from './discord-icon.svg'
import {ReactComponent as TwitterIcon} from './twitter-icon.svg'

const FooterSocialLink = ({type}: {type: string}) => {
    return (
        <div className='footer-social-link'>
            <a href="/swap">
                {type === 'github'? <GithubIcon />: ''}{type === 'discord' ? <DiscordIcon />: ''}{type === 'twitter' ? <TwitterIcon />: ''}
            </a>
        </div>
    )
}

export default FooterSocialLink