import { useState } from 'react';
import './LoadingModal.css'


const LoadingModalComponent = ({t}: {t: any}) => {
    const [message, setMessage] = useState<{
        type: string,
        text: string
    }>({type: '1', text: "Completed!"})

    const changeMessage = (event: any) => {
        var el = event.target;
        el = el.classList.contains("loading-modal-main-body") ? el : el.closest(".loading-modal-main-body")
        if (el.dataset.message == '') {
            el.classList.remove("message");
            el.classList.add("load");
        }
        else {
            setMessage({
                type: el.dataset.message,
                text: el.dataset.message == '1' ? 'Smart contract processing completed!' : "Smart contract processing error, try again later"
            });
            el.classList.add("message");
            el.classList.remove("load");
        }
    }
    return (
        <div className="loading-modal">
            <div className="loading-modal-overlay">
            </div>
            <div className="loading-modal-body">
                <div className="loading-modal-main">
                    <div className="loading-modal-header">
                        {t("Smart contract processing")}
                    </div>
                    <div className="loading-modal-main-body load" data-message="" onClick={changeMessage}>
                        <div className="loading-modal-load-wrapper">
                            <div className="loading-modal-load"></div>
                        </div>
                        <div className={`loading-modal-message-wrapper${message.type == '1'? ' completed': " failed"}`}>
                            <div className="loading-modal-message">
                                {t(message.text)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoadingModalComponent;
