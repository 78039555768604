import NavMoreMenuRowLink from '../nav-more-menu-row-link/nav-more-menu-row-link';
import './nav-more-menu-wrapper.css'

const NavMoreMenuRow = ({name, t, i18n}: {name: string, t: any, i18n: any}) => {
    return (
        <div className='nav-more-menu-row'>
            <div className='nav-more-menu-title'>
                {name === 'app' ? t('App'): ''}{name === 'company' ? t('Company'): ''}{name === 'protocol' ? t('Protocol'): ''}{name === 'help' ? t('Need help?'): ''}
            </div>
            <NavMoreMenuRowLink text={`${name === 'app' ? t('Vote'): ''}${name === 'company' ? t('Careers'): ''}${name === 'protocol' ? t('Governance'): ''}${name === 'help' ? t('Contact us'): ''}`} />
            <NavMoreMenuRowLink text={`${name === 'app' ? t('Analitics'): ''}${name === 'company' ? t('Blog'): ''}${name === 'protocol' ? t('Developers'): ''}${name === 'help' ? t('Help center'): ''}`} />
        </div>
    )
}

export default NavMoreMenuRow;