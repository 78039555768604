import "./block-number-block.css"


const BlockNumber = ({ web3Provider }: { web3Provider: any }) => {
    // setTimeout( () => {
    //     setInterval( async () => {
    //         var bn = document.querySelector('.popover-reference-element div') as HTMLElement;
    //         bn.textContent = String(await web3Provider.eth.getBlockNumber())
    //     }, 1000)
    // }, 1000)
    return (
        <div className='block-number-wrapper'>
            <div className='polling'>
                <span className='polling-text'>
                    <a className='polling-component' href="/">
                        <div className='popover-reference-element'>
                            <div></div>
                        </div>
                    </a>
                </span>
                <div className="polling-dot">
                </div>
            </div>
        </div>
    )
}

export default BlockNumber
