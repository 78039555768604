import './ConnectedWallet.css'
import { ReactComponent as CloseConnectedWallet } from './close-connect-wallet-icon.svg'
import { ReactComponent as UserWalletLogo } from './user-wallet-logo.svg'
import { ReactComponent as ConnectedWalletSettings } from './connect-wallet-settings-icon.svg'
import { ReactComponent as DisconectConnectedWallet } from './disconnect-wallet-icon.svg'
import { ReactComponent as CardIcon } from './card-icon.svg'
import { ReactComponent as NftsIcon } from './nfts-icon.svg'
import { ReactComponent as EthNetworkIcon } from './eth-network-icon.svg'
import InchLogo from './inch-logo.png'
import { ConnectWalletSettingsBlock } from './ConnectWallet'
import { useTranslation } from 'react-i18next'
import { universalProvider, web3Provider } from '.'
import { ETH_NODE, getAllTokenBalances, main_token, tokens } from './walletconnectHandlers'
import { useEffect, useState } from 'react'
import Web3 from 'web3'
import { Transaction } from 'ethereumjs-tx'

const ConnectedWallet = ({t, i18n, universalProvider, currentNetwork, tokens}: {t: any, i18n: any, universalProvider: any, currentNetwork: any, tokens: any}) => {

    const closeConnectedWallet = (event: any) => {
        document.querySelector('body')?.classList.remove('connected-wallet-modal-open')
    }

    return (
        <div className='connected-wallet'>
            <div className='close-connected-wallet' onClick={closeConnectedWallet}>
                <CloseConnectedWallet />
            </div>
            <div className='connected-wallet-background'></div>
            <div className='connected-wallet-wrapper'>
                <div className='connected-wallet-wrapper-second'>
                    <div className='connected-wallet-wrapper-third settings-status'>
                        <div className='connected-wallet-wrapper-forth'>
                            <ConnectedWalletHeader t={t} i18n={i18n} universalProvider={universalProvider} />
                            <ConnectedWalletBody t={t} i18n={i18n} currentNetwork={currentNetwork} tokens={tokens} />
                        </div>
                        <ConnectWalletSettingsBlock t={t} i18n={i18n} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConnectedWallet;


const ConnectedWalletHeader = ({t, i18n, universalProvider}: {t: any, i18n: any, universalProvider: any}) => {

    const compareAddress = (val: string) => {
        var dt = ''
        dt += val[0] + val[1] + val[2] + val[3] + val[4] + val[5] + '...' + val[val.length-4] + val[val.length-3] + val[val.length-2] + val[val.length-1]
        return dt
    }
    
    const openSettingMenu = (event: any) => {
        var e = event.target.closest('.settings-status')
        e?.classList.add('settings-open')
    }

    const disconnectWalletSession = async () => {
        await universalProvider.disconnect();
        var dv = (document.querySelector('#connect-wallet-button') as HTMLElement);
        dv.click();
        (document.querySelector('body') as HTMLElement).classList.remove('connected-wallet-modal-open');
    }

    return (
        <div className='connected-wallet-header'>
            <div className='connected-wallet-header-left'>
                <div className='connected-wallet-header-left-wallet'>
                    <div className='connected-wallet-header-left-wallet-svg'>
                        <UserWalletLogo />
                    </div>
                    <div className='connected-wallet-header-left-wallet-image'>
                        <img className='connected-wallet-header-left-wallet-img' src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTgiIGhlaWdodD0iNjUiIHZpZXdCb3g9IjAgMCA1OCA2NSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAgOS4zODk0OUwyOC44OTA3IDBWNjUuMDA0MkM4LjI1NDUgNTYuMzM2OSAwIDM5LjcyNDggMCAzMC4zMzUzVjkuMzg5NDlaIiBmaWxsPSIjMDUwMEZGIi8+CjxwYXRoIGQ9Ik01Ny43ODIyIDkuMzg5NDlMMjguODkxNSAwVjY1LjAwNDJDNDkuNTI3NyA1Ni4zMzY5IDU3Ljc4MjIgMzkuNzI0OCA1Ny43ODIyIDMwLjMzNTNWOS4zODk0OVoiIGZpbGw9InVybCgjcGFpbnQwX2xpbmVhcl8yMjAxXzY5NDIpIi8+CjxkZWZzPgo8bGluZWFyR3JhZGllbnQgaWQ9InBhaW50MF9saW5lYXJfMjIwMV82OTQyIiB4MT0iNTEuMzYxNSIgeTE9Ii00LjE1MjkzIiB4Mj0iMjkuNTM4NCIgeTI9IjY0LjUxNDciIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj4KPHN0b3Agb2Zmc2V0PSIwLjAyMTEyIiBzdG9wLWNvbG9yPSIjMDAwMEZGIi8+CjxzdG9wIG9mZnNldD0iMC4wNzYyNDIzIiBzdG9wLWNvbG9yPSIjMDA5NEZGIi8+CjxzdG9wIG9mZnNldD0iMC4xNjMwODkiIHN0b3AtY29sb3I9IiM0OEZGOTEiLz4KPHN0b3Agb2Zmc2V0PSIwLjQyMDA0OSIgc3RvcC1jb2xvcj0iIzAwOTRGRiIvPgo8c3RvcCBvZmZzZXQ9IjAuNjgyODg2IiBzdG9wLWNvbG9yPSIjMDAzOEZGIi8+CjxzdG9wIG9mZnNldD0iMC45MDI0NjUiIHN0b3AtY29sb3I9IiMwNTAwRkYiLz4KPC9saW5lYXJHcmFkaWVudD4KPC9kZWZzPgo8L3N2Zz4K" alt="" />
                    </div>
                </div>
                <div className='connected-wallet-header-left-address'>
                    <div className='connected-wallet-header-left-address-w'>
                        <div className='connected-wallet-header-left-address-wrapper'>
                            <div className='connected-wallet-header-left-address-text'>
                                <div className='connected-wallet-header-left-address-text-wrapper'>
                                    <span className='connected-wallet-header-left-address-span'>{universalProvider.session? compareAddress(universalProvider.session.namespaces.eip155.accounts[0].replace(/eip155:[1-9]{1,}:/g, '')): ''}</span>
                                </div>
                            </div>
                            <div className='connected-wallet-header-left-address-second'></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='connected-wallet-header-right'>
                <button className='connected-wallet-header-right-settings'>
                    <span className='connected-wallet-header-right-settings-span' onClick={openSettingMenu}>
                        <ConnectedWalletSettings />
                    </span>
                </button>
                <button className='connected-wallet-header-right-disconnect' onClick={disconnectWalletSession}>
                    <div className='connected-wallet-header-right-disconnect-wrapper'>
                        <span className='connected-wallet-header-right-disconnect-svg'>
                            <DisconectConnectedWallet />
                        </span>
                        <div className='connected-wallet-header-right-disconnect-text'>
                            <div className='connected-wallet-header-right-disconnect-text-wrapper'>
                                {t("Disconnect")}
                            </div>
                        </div>
                    </div>
                </button>
            </div>
        </div>
    )
}

const ConnectedWalletBody = ({t, i18n, currentNetwork, tokens}: {t: any, i18n: any, currentNetwork: any, tokens: any}) => {
    const [dt, setDt] = useState({balance: 0, tokens: tokens})
    useEffect(() => {
        async function getData() {
            var data: any = {balance: 0, tokens: []}
            if (universalProvider.session) {
                data = await getAllTokenBalances(tokens, currentNetwork, universalProvider.session.namespaces.eip155.accounts[0].replace(/eip155:[1-9]{1,}:/g, ''))
                setDt(data)
            }
            else {
                setDt(data)
            }
        }
        getData()
    }, [])
    const compareData = (data: any) => {
        var resp: any = []
        for (var i = 0; i < 4; i++) {
            resp.push(<ConnectedWalletTokenBlock Data={{
                token_logo: '',
                token_symbol: '',
                token_network_logo: '',
                token_network_name: '',
                token_balance: '',
                token_price: '',
            }} currency="$" />)
        }
        // data.tokens.forEach((e: any) => {
        //     resp.push(<ConnectedWalletTokenBlock Data={{
        //         token_logo: e.token_img,
        //         token_symbol: e.token_symbol,
        //         token_network_logo: e.network_img,
        //         token_network_name: e.network_name,
        //         token_balance: e.balance,
        //         token_price: e.usd_balance,
        //     }} currency="$" />)
        // })
        return resp
    }

    const sendTX = async () => {
        // var w3 = new Web3(universalProvider)
        // var tx = {
        //     to: "0x7d360aabdb577a91a399b936bad6ef08d2939944",
        //     from: "0xd8e4b62e124fe4195e54033c8fdb7fd89005ebbe",
        //     gasLimit: "0x55F0",
        //     gasPrice: w3.utils.toHex(100000),
        //     value: w3.utils.toHex(100000),
        //     data: "0x0",
        // }
        // const signPromise = w3.eth.signTransaction(tx).then((signedTx: any) => {
        //     const sentTx = w3.eth.sendSignedTransaction(signedTx.raw || signedTx.rawTransaction);
        //     sentTx.on("receipt", (receipt: any) => {
        //         console.log(receipt)
        //         // do something when receipt comes back
        //       });
        //       sentTx.on("error", (err: any) => {
        //         console.log(err)
        //         // do something on transaction error
        //       });
        //     })
        //     .catch((err: any) => {
        //         console.log(err)
        //       // do something when promise fails
        //     });
    }
    return (
        <div className='connected-wallet-body'>
            <div className='connect-wallet-body-top'>
                <div style={{width: "170px", height: "44px"}} className='loading connect-wallet-blank'>
                </div>
                <div style={{width: "100px", height: "16px", margin: "4px 0px 20px"}} className='loading connect-wallet-blank'>
                </div>
                {/* <div className='connect-wallet-body-top-balance'>
                    <div className='connect-wallet-body-top-balance-wrapper'>
                        <div className='connect-wallet-body-top-balance-text'>
                            <div className='connect-wallet-body-top-balance-text-wrapper'>
                                <span className='connect-wallet-body-top-balance-currency'>$</span>
                                <div className='connect-wallet-body-top-balance-text-wrapper-value'>{dt.balance}</div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            <div className='connect-wallet-body-middle'>
                <div className='connect-wallet-body-middle-action'>
                    <button className='connect-wallet-body-middle-button'>
                        <div className='connect-wallet-body-middle-button-wrapper'></div>
                        <div className='connect-wallet-body-middle-button-div'>
                            <CardIcon />
                            <div className='connect-wallet-body-middle-button-div-text'>{t("Buy")}</div>
                        </div>
                    </button>
                </div>
                <div className='connect-wallet-body-middle-action'>
                    <button className='connect-wallet-body-middle-button'>
                        <div className='connect-wallet-body-middle-button-wrapper'></div>
                        <div className='connect-wallet-body-middle-button-div'>
                            <NftsIcon />
                            <div className='connect-wallet-body-middle-button-div-text'>{t("View")} NFTs</div>
                        </div>
                    </button>
                </div>
            </div>
            <div className='connect-wallet-body-bottom'>
                <div className='connect-wallet-body-bottom-header'>
                    <div className='connect-wallet-body-bottom-header-option selected'>
                        <span>{t("Tokens")}</span>
                    </div>
                    <div className='connect-wallet-body-bottom-header-option'>
                        <span>NFTs</span>
                    </div>
                    <div className='connect-wallet-body-bottom-header-option'>
                        <span>{t("Pools")}</span>
                    </div>
                    <div className='connect-wallet-body-bottom-header-option'>
                        <span>{t("Activity")}</span>
                    </div>
                </div>
                <div className='connect-wallet-body-bottom-body'>
                    {dt?compareData(dt): []}
                </div>
            </div>
            {/* <div>
                <button onClick={sendTX}>Send tr</button>
            </div> */}
        </div>
    )
}

type UsetWalletTokenData = {
    token_logo: any,
    token_symbol: string,
    token_network_logo: any,
    token_network_name: string,
    token_balance: number,
    token_price: number,
}

const ConnectedWalletTokenBlock = ({Data, currency}: {Data: any, currency: string}) => {
    return (
        <div className='connect-wallet-token' style={{justifyContent: "space-between"}}>
            <div style={{width: "40px", height: "40px"}} className='loading connect-wallet-blank'>
            </div>
            <div className='connect-wallet-blank-column'>
                <div style={{width: "60px", height: "16px"}} className='loading connect-wallet-blank'>
                </div>
                <div style={{width: "90px", height: "10px"}} className='loading connect-wallet-blank'>
                </div>
            </div>
            <div className='connect-wallet-blank-column fl-end'>
                <div style={{width: "70px", height: "14px"}} className='loading connect-wallet-blank'>
                </div>
                <div style={{width: "50px", height: "14px"}} className='loading connect-wallet-blank'>
                </div>
            </div>
        </div>
        // <div className='connect-wallet-token'>
        //     <div className='connect-wallet-left'>
        //         <div className='connect-wallet-left-wrapper'>
        //             <div className='connect-wallet-token-image'>
        //                 <img src={Data.token_logo} alt={Data.token_symbol} />
        //             </div>
        //             <div className='connect-wallet-token-network-image'>
        //                 <img src={Data.token_network_logo} alt={Data.token_network_logo} />
        //             </div>
        //         </div>
        //     </div>
        //     <div className='connect-wallet-middle'>
        //         <div className='connect-wallet-middle-wrapper'>
        //             <div className='connect-wallet-token-name'>
        //                 <span className='connect-wallet-token-name-text'>{Data.token_symbol}</span>
        //             </div>
        //             <div className='connect-wallet-token-network-name'>
        //                 <span className='connect-wallet-token-network-name-text'>{Data.token_network_name}</span>
        //             </div>
        //         </div>
        //     </div>
        //     <div className='connect-wallet-right'>
        //         <div className='connect-wallet-right-wrapper'>
        //             <div className='connect-wallet-token-amount'>
        //                 <span className='connect-wallet-token-amount-text'>{Data.token_balance}</span>
        //             </div>
        //             <div className='connect-wallet-token-price'>
        //                 <span className='connect-wallet-token-price-text'>{currency}{Data.token_price}</span>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}
