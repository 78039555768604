import { ReactComponent as NavLinkArrow } from './nav-link-arrow.svg'
import './nav-bar-link-arrow.css'
import NavMoreMenu from '../nav-more-menu/nav-more-menu'
import i18n from '../../i18n'

const NavBarLinkArrow = ({t, i18n}: {t: any, i18n: any}) => {
    const openMoreMenu = (event: any) => {
        console.log(1)
        var el = event.target
        if (el != null) {
            var el1 = el.closest('.nav-more-menu')
            if (el1 == null) {
                if (!el.classList.contains('more-button')) {
                    el = el.closest('.more-button')
                }
                if (el.classList.contains('open')) {
                    el.classList.remove('open')
                    window.addEventListener('click', closeMoreMenu)
                }
                else {
                    el.classList.add('open')
                    setTimeout(() => {window.addEventListener('click', closeMoreMenu)}, 100)
                }
            }
        }
    }
    const closeMoreMenu = (event: any) => {
        var el = event.target
        console.log(el.classList.contains('nav-more-menu'))
        if (!el.classList.contains('nav-more-menu')) {
            el = el.closest('.nav-more-menu')
        }
        if (el == null) {
            document.querySelector('.more-button.open')?.classList.remove('open')
            window.removeEventListener('click', closeMoreMenu)   
        }
    }
    return (
        <div className='more-button' onClick={openMoreMenu}>
            <div className='more-button-wrapper'>
                <NavLinkArrow />
            </div>
            <NavMoreMenu t={t} i18n={i18n} />
        </div>
    )
}

export default NavBarLinkArrow;