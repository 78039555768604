import './nav-bar.css'
import NavBarLogo from '../nav-bar-logo/nav-bar-logo';
import NavBarNetworkChoise from '../nav-bar-network-choise/nav-bar-network-choise';
import NavBarLinks from '../nav-bar-links/nav-bar-links';
import NavBarSearchBar from '../nav-bar-search-bar/nav-bar-search-bar';
import NavBarSearchButton from '../nav-bar-search-button/nav-bar-search-button'
import NavBarConnectWallet, { CNavBarConnectWallet } from '../nav-bar-connect-wallet/nav-bar-connect-wallet';
import MobileSearchBar from '../mobile-search-bar/mobile-search-bar';
import { useState } from 'react';


const NavBar = ({t, i18n, networks, universalProvider, walletConnectModal, dt, setDt, setToTokenData, setFromTokenData, currentNetwork, setCurrentNetwork, setTokens}: {t: any, i18n: any, networks: any, universalProvider: any, walletConnectModal: any, dt: number, setDt: any, setToTokenData: any, setFromTokenData: any, currentNetwork: any, setCurrentNetwork: any, setTokens: any}) => {
    return (
        <nav className='nav-bar'>
            <div className='nav-bar-wrapper'>
                <div className='left-side'>
                    <NavBarLogo />
                    <NavBarNetworkChoise tp={'mobile'} networks={networks} universalProvider={universalProvider} walletConnectModal={walletConnectModal} dt={dt} setDt={setDt} setFromTokenData={setFromTokenData} setToTokenData={setToTokenData} currentNetwork={currentNetwork} setCurrentNetwork={setCurrentNetwork} setTokens={setTokens} />
                    <NavBarLinks tp={'md'} t={t} i18n={i18n} />
                </div>
                <NavBarSearchBar t={t} i18n={i18n} />
                <div className='rigth-side'>
                    <div className='right-side-wrapper'>
                        <div className='mobile-serach-wrapper'>
                            <MobileSearchBar t={t} i18n={i18n} />
                            <NavBarSearchButton tp={'mobile'} />
                        </div>
                        <NavBarNetworkChoise tp={'pc'} networks={networks} universalProvider={universalProvider} walletConnectModal={walletConnectModal} dt={dt} setDt={setDt} setFromTokenData={setFromTokenData} setToTokenData={setToTokenData} currentNetwork={currentNetwork} setCurrentNetwork={setCurrentNetwork} setTokens={setTokens} />
                        <CNavBarConnectWallet t={t} i18n={i18n} universalProvider={universalProvider} walletConnectModal={walletConnectModal} />
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default NavBar;